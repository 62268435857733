import * as React from "react";

export class AboutComponent extends React.Component {
  render() {
    return <section className="about-component">
      <div className="za-body">
        <div className="za-about-section odd za-mission-statement">
          <p className="za-about-content">
            <span className="za-about-subject">Zeta Analytics</span> creates innovative market intelligence tools for the defense and aerospace industries. Our mission is to provide customers with a clear vision of the strategic landscape and generate previously unattainable insights. Our approach combines subject-matter research and investigative expertise with leading edge visual analytics and data modeling. We develop tools that are easy to access, easy to use and clearly deliver the tactical and strategic picture.
            </p>
        </div>
        <div className="za-about-section even">
          <p>
            contact us at <strong>info@zetanalytics.com</strong>
          </p>
        </div>
        <div className="za-about-section odd">
          <div className="za-about-content">
            <p>
              <span className="za-about-subject">Zachary Lum</span> is the president and co-founder of Zeta Analytics. Prior to forming the company, Mr. Lum managed strategy and M&A planning for a $1 billion European combat vehicle prime contractor, where he wrote the company’s first strategic plan. In 2012, he conducted a competitive analysis of the European mobility systems market for a major US military driveline manufacturer.
              </p>
            <p>
              Mr. Lum’s 20-year career in the defense arena has also
              included work as a defense journalist and as an analyst and
              senior manager for leading DC-based national security
              consultancies. As a strategic planner for the US Air Force
              during the 2001 and 2006 Quadrennial Defense Reviews, he led
              the development of several operations-level tools, including a
              brigade combat team air mobility deployment model, an
              air-ground expeditionary force packaging model and a Pacific
              power projection model of land-based and carrier-based strike
              airpower.
              </p>
            <p>
              Mr. Lum has recently published articles in <em>
                Aviation Week Defense Technology Edition
                </em>, <em>Wired Danger Room</em> and the <em>
                Journal of Electronic Defense
                </em>.
              </p>
            <p>
              Mr. Lum is a graduate of Williams College and earned a
              Master’s degree from the Fletcher School of Law & Diplomacy at
              Tufts University.
              </p>
          </div>
        </div>
        <div className="za-about-section even">
          <div className="za-about-content">
            <p>
              <span className="za-about-subject">William Reppun</span> is the executive vice president, chief technology officer and co-founder of Zeta Analytics. His previous experience in the defense industry includes three years with a marine R&D firm based in Honolulu, Hawaii, designing underwater lifting bodies and high-speed planing hulls for the US military.
              </p>
            <p>
              His most recent work involves data mining and data aggregation
              on a massive scale for a startup based in Toronto, Ontario.
              </p>
            <p>William is a graduate of Harvard University.</p>
          </div>
        </div>
      </div>
    </section>;
  }
}