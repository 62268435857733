
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from 'reactstrap';
import MultiSelect from "react-multi-select-component";

import { fetchData, setChartType, saveData, setCluster } from "../../redux/reducers/chartData/actions";
import { getFilters, getChartType } from "../../redux/reducers/chartData/selectors";
import ChartComponent from '../../components/network/ChartComponent';
// import { getFetchingData } from "../../redux/reducers/chartData/selectors";

import FilterModal from '../../components/filters/FilterModal';
import FiltersMae from "../../components/filters/Filters_MAE";
import { GROUPS } from '../../constants';
import { fetchClusters } from "../../redux/reducers/chartData/actions";
import { getClusters } from "../../redux/reducers/chartData/selectors";

const CHART_TYPE = 'mae';
const GROUP_OPTS = [
  { label: 'CORP GROUP', value: 'corp_group' },
  { label: 'SYSTEM FAMILY', value: 'system_family' },
  { label: 'PLATFORM FAMILY', value: 'platform_family' },
  { label: 'MODEL VERSION', value: 'model_version' },
];

// MultiSelect Filters component
const MultiSelectComp = ({ clusterValue }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState([]);
  const [reset, setReset] = useState(false);
  const clusters = useSelector(state => getClusters(state));



  const selectValue = useCallback((val) => {
    setValue(val);
    dispatch(setCluster(val));
  }, []);


  // on component load
  useEffect(() => {
    if (clusterValue && clusterValue.value) {
      dispatch(fetchClusters(CHART_TYPE, clusterValue.value));
    }
  }, [clusterValue]);


  useEffect(() => {
    console.log(clusters)
  }, [clusters]);

  useEffect(() => {
    if (reset) {
      selectValue([]);
      setReset(false);
    }
  }, [reset, setReset, selectValue])

  return (
    <MultiSelect
      className='group-multi-select'
      options={clusters}
      value={value}
      onChange={(val) => selectValue(val)}
      labelledBy={"Cluster"}
      overrideStrings={{ selectSomeItems: 'Select Cluster...' }}
    />
  );
};


const MaeGraph = () => {
  const childRef = useRef();
  const dispatch = useDispatch();
  const chartType = useSelector(state => getChartType(state));

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggledd = () => setDropdownOpen(prevState => !prevState);


  const [clusterValue, selectClusterValue] = useState({});

  const changeMap = (type) => {
    if (chartType !== type) {
      dispatch(saveData({ nodes: [], links: [] }));
      dispatch(setChartType(type));
    }
  };

  useEffect(() => {
    console.log(clusterValue)
  }, [clusterValue]);

  // const fetchingData = useSelector(state => getFetchingData(state));
  // console.log(fetchingData);

  const toggle = () => {
    childRef.current.toggle();
  }

  return (
    <section className="mae-graph">

      {/* top buttons */}
      <div className="row">
        <div className="col-2">
          <Button color="outline-secondary" onClick={toggle}>Toggle Filters</Button>
        </div>
        <div className="col-2">
          <Button color="outline-secondary" onClick={() => dispatch(fetchData('mae', [], chartType))}>Fetch Data</Button>
        </div>
        <div className="col-1">
          <Button color="outline-secondary" onClick={() => changeMap('map')}>Map</Button>
        </div>
        <div className="col-1">
          <Button color="outline-secondary" onClick={() => changeMap('chart')}>Network</Button>
        </div>
        <div className="col-2">
          <Dropdown isOpen={dropdownOpen} toggle={toggledd}>
            <DropdownToggle caret>
              {clusterValue.label || 'Select Cluster Group'}
            </DropdownToggle>
            <DropdownMenu>
              {GROUP_OPTS.map((k, i) => {
                return <DropdownItem key={i}
                  onClick={() => { selectClusterValue(k) }}>
                  {k.label}
                </DropdownItem>
              })}
            </DropdownMenu>
          </Dropdown>
        </div>
        <div className="col-2">
          {/* <Button color="outline-secondary">Cluster Nodes</Button> */}
          {chartType === 'chart' ? <MultiSelectComp clusterValue={clusterValue} /> : ''}
        </div>
      </div>

      {/* Filters modal */}
      <FilterModal ref={childRef} title='Mae Chart Filters'>
        <FiltersMae type={CHART_TYPE} />
      </FilterModal>

      {/* main chart */}
      <ChartComponent type={CHART_TYPE} chartDataType="mae" clusterValue={clusterValue}></ChartComponent>

    </section >
  );
};


export default MaeGraph;