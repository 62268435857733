import * as React from "react";
import { Link } from 'react-router-dom';

export class AboutSightLineComponent extends React.Component {
  render() {
    const filterImgWidth = { maxWidth: '70%' };
    return <section className="about-sightline">
      <div className="za-body">
        <div className="za-header">
          <h1 className="za-title za-title-medium">ABOUT SIGHTLINE</h1>
        </div>
        <div className="container-fluid actionate">
          <div className="row">
            <div className="col-12">
              <div className="za-about-content">
                <p>Sightline creates massive “node and edge” visualizations of integrators and suppliers linked to platforms; platforms linked across families, programs and variants; and companies linked together by strategic alliances. It connects disparate bits of open-source information with original research to reveal the market’s big picture, while capturing relationships that may not be widely known or understood.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h2 className="za-about-subject">FEATURES</h2>
              <div className="za-about-content">
                <p>Sightline is built with a new generation of graph database and visualization technology to offer an intuitive, easy-to-use web application. Key to its functionality are powerful search, filter and highlight utilities that allow the user to drill into the “fuzzball” to discover connections and generate insights. Graph nodes and edges are dynamic database elements that call up detailed overviews and analyses of companies, platforms, subsystems and the relationships among them.</p>
                <img className="img-fluid" alt="" style={filterImgWidth} src={require('../../assets/images/filters.jpg')} />
                <ul>
                  <li>Current <i>Military Ground Vehicles</i> release contains over 4,000 discrete connections among 700-plus companies and approx. 250 vehicle families (totaling over 900 individual vehicle programs).</li>
                  <li>Simple-to-access web application built with a client-side JavaScript framework. No software installation needed, just log on and use.</li>
                  <li>Simple to use. Hover & click, pan & zoom mouse navigation and commands.</li>
                  <li>Filter and highlight across numerous node and edge properties. Combine filters for exceptionally fine-tuned results.</li>
                  <li>User customization options available with full DB transfer.</li>
                  <li>Export results as image files for use in analyses, briefings, etc.</li>
                </ul>
                <p>Supplementing the main network visualization is a toolkit of interactive charts and graphs that track specific market trends and developments, including:</p>
                <ul>
                  <li>APC/IFV installed and projected production volume by manufacturer</li>
                  <li>Map-based vehicle inventories by country</li>
                  <li>Market opportunities tracker</li>
                </ul>
                <p>
                  There is also an interactive table-based interface to the database (see <Link to="/graph-gallery">Gallery</Link>) with a palette of filter and highlight tools (see <Link to="/graph-gallery">Gallery</Link>).
                  </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <h2 className="za-about-subject">
                SIGHTLINE FOR YOUR BOTTOM LINE
                </h2>
              <div className="za-about-content">
                <p>
                  Sightline is a valuable time and resource saver for your
                  organization. One click on a company or platform node
                  instantly reveals its web of relationships, saving hours
                  of analyst research. From there, the visual database
                  encourages the tracing of second- and third-degree
                  connections and beyond—results difficult or impossible to
                  achieve with traditional relational databases. Married
                  with professional CI techniques, Sightline becomes a
                  potent force multiplier, helping your team make the
                  connections to identify opportunities, spot threats and
                  predict market trends.
                  </p>
                <p>Applications include:</p>
                <p>
                  <b>Competitive analysis.</b> Keep profiles of competitors up to date with their latest partnerships, joint ventures and acquisitions. Have their organizational structures at your fingertips. Assess the competitiveness of their product portfolios by examining their global installed vehicle base and their positioning for current and emerging opportunities. Examine platform networks to identify technology differentiators, redundancies and gaps.
                  </p>
                <p>
                  <b>M&A targeting.</b> Your senior leadership probably has its consensus one or two top acquisition targets. But finding the hidden gems—the small, under-the-radar tech company that fills a gap in your product strategy, or the overlooked regional manufacturer with ties into strategic expansion markets—is a trickier challenge. An intriguing candidate may have surfaced within multiple internal analyses, but the stovepiped nature of these products prevented your team from seeing the connection. Sightline literally draws these connections for you. Let your M&A team use Sightline as a first-pass market surveillance tool, setting up the deep dive on high-potential discoveries.
                  </p>
                <p>
                  <b>Supply chain and subcontractor analysis.</b> You have a good handle on your key suppliers, but how well do your understand their onward relationships? Use Sightline to trace their connections to your partners and competitors, identifying potential complements as well as vulnerabilities. Detect the critical nodes that both you and your competitors are dependent upon, and feed this into your contingency planning.
                  </p>
                <p>
                  <b>Industrial participation support.</b> Use Sightline to sift the options for teammates to meet local content requirements in upcoming competitions. Examine their roles on current or past vehicle programs and their experience in alliance formation. Perform due diligence on their ownership and stakeholding structures.
                  </p>
                <p>
                  <b>Opportunities identification:</b> Filter platforms to see ongoing and coalescing competitions. Highlight legacy vehicle fleets to spot potential recap/upgrade opportunities.
                  </p>
                <p>
                  <b>Predictive analysis.</b> Visual information lends itself particularly well to detecting patterns and trends. Sightline can take your competitive intelligence a step further to support early warning of opportunities and threats. Mine your competitors’ networks to anticipate their next moves and understand their growth strategies: are they acquiring product or technology competencies that could challenge your portfolio in the future? Are their alliances taking a geographic vector that points toward one of your franchise markets?
                  </p>
                <p>
                  <b>Scenario planning.</b> Isolate groups of company and/or platform nodes to model the impact of market-moving scenarios. For instance, filter the graph to the vehicle portfolios of two potential merger candidates to show their combined product range, supplier network and market presence.
                  </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Link to="/contact" className="btn btn-primary">
                GET ACCESS
                </Link>
            </div>
          </div>

        </div>
      </div>
    </section>;
  }
}