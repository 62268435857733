import {
  FETCH_DATA_START,
  FETCH_DATA_ERROR,
  FETCH_DATA_SUCCESS,
  CLEAR_ERROR,
  SAVE_DATA,
  SELECT_NODE,
  HOVER_NODE,
  SELECT_EDGE,
  SAVE_MAP,
  CLEAR_SELECTION,
  SET_CHART_TYPE,
  SAVE_FILTERS,
  SELECTED_FILTERS,
  SET_CLUSTER,
  SAVE_CLUSTERS
} from "./actions";

const initialState = {
  data: {},
  filters: {},
  clusters: [],
  selFilters: {},
  selFiltersArr: [],
  selCluster: [],
  map: null,
  chartType: 'chart',
  fetchingData: false,
  error: "",
  selectedType: '',
  prevSelectedType: '',
  selectedNode: {},
  prevSelectedNode: {},
  hoveredNode: false,
  selectedEdge: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA_START: {
      return {
        ...state,
        fetchingData: true
      };
    }
    case FETCH_DATA_SUCCESS: {
      return {
        ...state,
        error: "",
        fetchingData: false
      };
    }
    case SAVE_DATA: {
      return {
        ...state,
        data: action.payload
      }
    }

    case SET_CLUSTER: {
      return {
        ...state,
        selCluster: action.payload
      }
    }

    case SAVE_FILTERS: {
      const filt = {};
      filt[action.chartType] = action.payload;
      return {
        ...state,
        filters: {
          ...state.filters,
          ...filt
        }
      }
    }

    case SAVE_CLUSTERS: {
      return {
        ...state,
        clusters: action.payload
      }
    }

    case SELECTED_FILTERS: {
      return {
        ...state,
        selFilters: action.payload,
        selFiltersArr: action.filtersArr
      }
    }

    case SAVE_MAP: {
      return {
        ...state,
        map: action.payload
      }
    }
    case SET_CHART_TYPE: {
      return {
        ...state,
        chartType: action.payload
      }
    }
    case CLEAR_ERROR: {
      return {
        ...state,
        error: ""
      }
    }
    case FETCH_DATA_ERROR: {
      return {
        ...state,
        fetchingData: false,
        error: action.payload
      }
    }
    case CLEAR_SELECTION: {
      return {
        ...state,
        selectedType: '',
        selectedNode: {},
        selectedEdge: {}
      }
    }
    case SELECT_NODE: {
      return {
        ...state,
        selectedType: 'node',
        previousSelectedType: state.selectedType || 'node',
        selectedNode: action.payload,
        hoveredNode: {},
        selectedEdge: {}
      }
    }
    case HOVER_NODE: {
      if (action.payload) {
        return {
          ...state,
          previousSelectedType: state.selectedType,
          selectedType: 'hover-node',
          hoveredNode: action.payload
        }
      } else {
        return {
          ...state,
          selectedType: state.previousSelectedType,
          hoveredNode: {}
        }
      }
    }
    case SELECT_EDGE: {
      return {
        ...state,
        selectedType: 'edge',
        selectedEdge: action.payload,
        selectedNode: {},
        hoveredNode: {}
      }
    }
    default:
      return state;
  }
}
