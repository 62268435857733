class ApiService {
  constructor(url) {
    this.apiUrl = url || process.env.REACT_APP_API_URL;
    console.log(this.apiUrl)
  }

  setUrl(url) {
    this.apiUrl = url;
  }

  getUrl() {
    return this.apiUrl;
  }

  async fetchApi(path = "", method = "GET", body) {
    const token = localStorage.getItem("zeta-token");
    const url = `${this.apiUrl}/${path}?token=${token}`;
    const opts = { method };
    if (body) {
      opts.body = JSON.stringify(body);
    }
    console.log(url)
    // opts.headers = new Headers({
    //   Authorization: `Bearer ${token}`
    // })
    const res = await fetch(url, opts);
    return res.json();
  }

  getData(dbType, filters) {
    const path = 'chart';
    return this.fetchApi(path, 'POST', {
      dbType,
      filters: filters
    });
  }

  getFilters(type) {
    const path = `chart/filters/${type}`;
    return this.fetchApi(path);
  }

  getClusters(dbType, column) {
    const path = `chart/clusters/${dbType}/${column}`;
    return this.fetchApi(path);
  }

  loginUser(username, password) {
    const path = 'auth/login';
    return this.fetchApi(path, 'POST', {
      username,
      password
    });
  }
}

export default ApiService;