
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Button } from 'reactstrap';
import MultiSelect from "react-multi-select-component";

import { fetchData, setChartType, saveData, setCluster } from "../../redux/reducers/chartData/actions";
import { getFilters, getChartType } from "../../redux/reducers/chartData/selectors";
import ChartComponent from '../../components/network/ChartComponent';
// import { getFetchingData } from "../../redux/reducers/chartData/selectors";

import FilterModal from '../../components/filters/FilterModal';
import Filters from "../../components/filters/Filters";
import { GROUPS } from '../../constants';

const CHART_TYPE = 'labs';

// MultiSelect Filters component
const MultiSelectComp = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState([]);
  const [reset, setReset] = useState(false);

  const selectValue = useCallback((val) => {
    setValue(val);
    dispatch(setCluster(val));
  }, []);

  useEffect(() => {
    if (reset) {
      selectValue([]);
      setReset(false);
    }
  }, [reset, setReset, selectValue])

  return (
    <MultiSelect
      className='group-multi-select'
      options={GROUPS}
      value={value}
      onChange={(val) => selectValue(val)}
      labelledBy={"Cluster"}
      overrideStrings={{ selectSomeItems: 'Select Cluster...' }}
    />
  );
};


const LabsGraph = () => {
  const childRef = useRef();
  const dispatch = useDispatch();
  const chartType = useSelector(state => getChartType(state));
  const filters = useSelector(getFilters);
  console.log(filters);

  const changeMap = (type) => {
    if (chartType !== type) {
      dispatch(saveData({ nodes: [], links: [] }));
      dispatch(setChartType(type));
    }
  };

  // const fetchingData = useSelector(state => getFetchingData(state));
  // console.log(fetchingData);

  const toggle = () => {
    childRef.current.toggle();
  }

  return (
    <section className="labs-graph">

      {/* top buttons */}
      <div className="row">
        <div className="col-2">
          <Button color="outline-secondary" onClick={toggle}>Toggle Filters</Button>
        </div>
        <div className="col-2">
          <Button color="outline-secondary" onClick={() => dispatch(fetchData('labs', [], chartType))}>Fetch All Data</Button>
        </div>
        <div className="col-2">
          <Button color="outline-secondary" onClick={() => changeMap('map')}>Map Chart</Button>
        </div>
        <div className="col-2">
          <Button color="outline-secondary" onClick={() => changeMap('chart')}>Network Chart</Button>
        </div>
        <div className="col-2">
          {/* <Button color="outline-secondary">Cluster Nodes</Button> */}
          {chartType === 'chart' ? <MultiSelectComp /> : ''}
        </div>
      </div>

      {/* Filters modal */}
      <FilterModal ref={childRef} title='Labs Chart Filter'>
        <Filters type={CHART_TYPE} />
      </FilterModal>

      {/* main chart */}
      <ChartComponent type={CHART_TYPE} chartDataType="labs"></ChartComponent>

    </section >
  );
};


export default LabsGraph;