import * as L from 'leaflet';
import * as LDec from 'leaflet-polylinedecorator';
import * as LClus from 'leaflet.markercluster';
import * as d3 from 'd3';
import * as _ from 'lodash';
import NetworkChartClass from './network_chart';
import { COLORS, RADIUS, linkMouseOver, linkMouseOut } from './utils';

import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';

const MAPBOX_TOKEN = `pk.eyJ1Ijoic3VzaG1pdHNhcm1haCIsImEiOiJja2RlNTkwOWYwNHYwMnFvYmFwYzJqZzJ0In0.LekMYDdJl-Vaz8HQfr-o5g`;
const MAPBOX_URL = `https://api.mapbox.com/styles/v1/sushmitsarmah/ckde4ypdf00tu1is3cm2n2q85/tiles/{z}/{x}/{y}?access_token=${MAPBOX_TOKEN}`;

export default class NetworkMapClass {
  width;
  height;
  map;
  markersLayer;
  markersCluster;
  edgesLayer;

  constructor() {
    this.networkChart = new NetworkChartClass();

    this.initMap = this.initMap.bind(this);
    this.updateMap = this.updateMap.bind(this);
    this.stopChartSimulation = this.stopChartSimulation.bind(this);
    this.isolateNodesEdgesClick = this.isolateNodesEdgesClick.bind(this);
    this.isolateNodesEdgesHover = this.isolateNodesEdgesHover.bind(this);
    this.filterNodesLinks = this.filterNodesLinks.bind(this);
  }

  initMap = (
    container, opts
  ) => {
    this.map = L.map(container).setView([opts.lat, opts.lng], opts.zoom);

    L.tileLayer(MAPBOX_URL, {
      attribution: `
        <span>Map data &copy;</span>
        <a href="https://www.openstreetmap.org/">
          OpenStreetMap
        </a>
        <span>contributors,</span>
        <a href="https://creativecommons.org/licenses/by-sa/2.0/">
          CC-BY-SA
        </a>,
        <span>Imagery ©</span>
        <a href="https://www.mapbox.com/">Mapbox</a>
      `,
      maxZoom: 18,
      minZoom: 3,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
      accessToken: MAPBOX_TOKEN,
      noWrap: true
    }).addTo(this.map);

    this.map.setMaxBounds(  [[-90,-180],   [90,180]]  )

    this.markersLayer = new L.LayerGroup();
    this.markersCluster = L.markerClusterGroup({
      disableClusteringAtZoom: 5
    });
    // this.markersCluster.addTo(this.map);

    this.edgesLayer = new L.LayerGroup();
    this.arrowLayer = new L.LayerGroup();

    this.arrowLayer.addTo(this.map);
    this.edgesLayer.addTo(this.map);
    this.markersLayer.addTo(this.map);


    this.map.on('zoomend', () => {
      // console.log('###', this.map.getZoom())
      if (this.map.getZoom() < 5) {
        this.map.removeLayer(this.markersLayer);
        this.map.addLayer(this.markersCluster);
      } else {
        this.map.removeLayer(this.markersCluster);
        this.map.addLayer(this.markersLayer);
      }
    });

  }

  getPattern(edge, classList = []) {
    const className = `map-arrow hidden ${classList.join(' ')}`
    return [
      {
        offset: '50%',
        repeat: 0,
        symbol: L.Symbol.arrowHead({
          pixelSize: 10,
          polygon: true,
          pathOptions: {
            className,
            fill: true,
            fillOpacity: 1,
            weight: (edge.properties.width * 2) || 2,
            stroke: true,
            color: edge.properties.color ? `rgb(${edge.properties.color})` : 'rgb(255, 255, 255)',
            fillColor: edge.properties.color ? `rgb(${edge.properties.color})` : 'rgb(255, 255, 255)'
          }
        })
      }
    ];
  }

  updateMap = ({
    data,
    // width,
    // height,
    dispatch,
    selectNode,
    selectEdge,
    clearSelection,
    tooltipRef
  }) => {
    window.nodes = data.nodes;
    console.log(data.nodes);
    this.edgesLayer.clearLayers();
    this.markersLayer.clearLayers();
    this.markersCluster.clearLayers();
    this.arrowLayer.clearLayers();

    d3.forceSimulation()
      .nodes(data.nodes)
      .force("link", d3.forceLink(data.links).id(d => d.nodeId).distance(100));

    data.links
      .sort((a, b) => +b.source.type_code - +a.source.type_code)
      .forEach(edge => {
        // if (edge.properties.labels.indexOf('Network') !== -1) {
        const latlngs = [
          [edge.source.lat, edge.source.long],
          [edge.target.lat, edge.target.long],
        ];

        let edgeColor;
        if(edge.properties.color) {
          edgeColor = `rgb(${edge.properties.color})`;
        } else if(edge.source.labels[0] === 'SYSTEM' && edge.target.labels[0] === 'PLATFORM') {
          edgeColor = '#66c2a5';
        } else if(edge.source.labels[0] === 'COMPANY' && edge.target.labels[0] === 'SYSTEM') {
          edgeColor = '#fc8d62';
        } else if(edge.source.labels[0] === 'SYSTEM' && edge.target.labels[0] === 'SYSTEM') {
          edgeColor = '#8da0cb';
        } else if(edge.source.labels[0] === 'COMPANY' && edge.target.labels[0] === 'COMPANY') {
          edgeColor = '#bc80bd';
        } else {
          edgeColor = 'rgb(255, 255, 255)';
        }

        let dashArray = '0';
        if (edge.properties.style) {
          const style = edge.properties.style;
          dashArray = +style === 1 ? "0" :
            +style == 2 ? "20,10" : "10,10"
        } else if(edge.properties.operationalStatus || edge.properties.status) {
          if(
            (
              edge.source.labels[0] === 'SYSTEM' &&
              edge.target.labels[0] === 'PLATFORM' &&
              edge.properties.operationalStatus === 'Phaseout'
            ) ||
            (
              edge.source.labels[0] === 'COMPANY' &&
              edge.target.labels[0] === 'SYSTEM' &&
              edge.properties.status === 'Competitor'
            )
          ) {
            dashArray = "20,10";
          } else {
            dashArray = '0';
          }
        } else {
          dashArray = "0";
        }

        const polyline = L.polyline(latlngs, {
          color: edgeColor,
          weight: edge.properties.width * 2 || 2,
          dashArray,
          opacity: 1,
          className: 'map-edge hidden',
          props: edge
        }); // .addTo(this.markersLayer);

        const arrowHead = L.polylineDecorator(polyline, {
          props: edge,
          patterns: this.getPattern(edge)
        });

        polyline.on('click', d => {
          dispatch(selectEdge(edge.properties));
        });

        polyline.on('mouseover', d => {
          linkMouseOver(edge, tooltipRef, d.originalEvent);
        });

        polyline.on('mouseout', d => {
          linkMouseOut(tooltipRef);
        });

        this.arrowLayer.addLayer(arrowHead);
        this.edgesLayer.addLayer(polyline);
        // }
      });

    data.nodes
      .sort((a, b) => +b.type_code - +a.type_code)
      .forEach(node => {
        const circle = L.circleMarker([node.lat, node.long], {
          fillColor: COLORS[+node.type_code] || `rgb(${node.type_code})`,
          color: '#fff',
          fillOpacity: 1,
          weight: 1.5,
          radius: RADIUS,
          className: 'map-node',
          props: node
        }); // .addTo(this.edgesLayer);

        circle.bindTooltip(node.id, {
          permanent: true,
          direction: 'top',
          className: 'map-label'
        });

        circle.on('click', (d) => {
          dispatch(selectNode(node));
          this.isolateNodesEdgesClick(node, d);
        });

        circle.on('mouseover', (d) => {
          dispatch(selectNode(node));
          this.isolateNodesEdgesHover(node, d);
        });

        circle.on('mouseout', (d) => {
          this.edgesLayer.eachLayer(edge => {
            L.DomUtil.removeClass(edge._path, 'highlight-link')
            L.DomUtil.removeClass(edge._path, 'isolated-hover-show');
          });

          this.arrowLayer.eachLayer(arrow => {
            const classList = arrow.options.patterns[0].symbol.options.pathOptions.className.split(' ');
            const index1 = classList.indexOf('highlight-link');
            if (index1 !== -1) {
              classList.splice(index1, 1);
            }
            const index2 = classList.indexOf('isolated-hover-show');
            if (index2 !== -1) {
              classList.splice(index2, 1);
            }
            arrow.setPatterns(this.getPattern(arrow.options.props, classList));
          });

          // console.log(this.markersLayer.layer.getAllChildMarkers());

          this.markersLayer.eachLayer(node => {
            // console.log(node)
            // console.log(this);
            // L.DomUtil.removeClass(node.options, 'isolated-hover-show')
            L.DomUtil.removeClass(node._path, 'isolated-hover-show')
          });

          const clicked = L.DomUtil.hasClass(d.target._path, 'node-clicked');
          if (!clicked) {
            dispatch(clearSelection());
          }
        });

        this.markersLayer.addLayer(circle);
        this.markersCluster.addLayer(circle);
      });

    this.markersLayer.setZIndex(2);
    this.edgesLayer.setZIndex(1);
    this.arrowLayer.setZIndex(0);
    // this.networkChart.updateChart({
    //   data,
    //   width: this.width,
    //   height: this.height,
    //   dispatch,
    //   selectNode,
    //   selectEdge,
    //   clearSelection,
    //   positionType: 'map',
    //   map: this.map
    // });
  }

  stopChartSimulation = () => {
    this.networkChart.stopSimulation();
  }

  filterNodesLinks(node) {
    let nodes = [];
    let links = [];
    this.edgesLayer.eachLayer(edge => {
      const link = edge.options.props;
      if (
        link.source.nodeId === node.nodeId ||
        link.target.nodeId === node.nodeId
      ) {
        nodes.push(link.source);
        nodes.push(link.target);
        links.push(link);
      }
    });
    nodes = _.uniq(nodes);
    links = _.uniq(links);
    return {
      nodes,
      links
    }
  }

  isolateNodesEdgesClick(clickedNode, d) {
    // means previously clicked -- if yes it will toggle
    const isClickedNode = L.DomUtil.hasClass(d.target._path, 'node-clicked');

    if (!isClickedNode) {
      const { nodes, links } = this.filterNodesLinks(clickedNode);
      if (nodes.length > 0 && links.length > 0) {

        this.edgesLayer.eachLayer(edge => {
          // L.DomUtil.addClass(edge._path, 'hidden');
          L.DomUtil.removeClass(edge._path, 'isolated-link');
          if (links.indexOf(edge.options.props) !== -1) {
            // L.DomUtil.removeClass(edge._path, 'hidden');
            L.DomUtil.addClass(edge._path, 'isolated-link');
          }
        });


        this.arrowLayer.eachLayer(arrow => {
          const edge = arrow.options.props;
          if (links.indexOf(edge) !== -1) {
            arrow.setPatterns(this.getPattern(edge, ['isolated-link']));
          } else {
            arrow.setPatterns(this.getPattern(edge, []));
          }
        });

        this.markersLayer.eachLayer(marker => {
          L.DomUtil.addClass(marker._tooltip._contentNode, 'hidden');
          L.DomUtil.addClass(marker._path, 'hidden');
          L.DomUtil.removeClass(marker._path, 'node-clicked');
          L.DomUtil.removeClass(marker._path, 'isolated-node');

          const node = marker.options.props;
          if (nodes.indexOf(node) !== -1) {
            L.DomUtil.removeClass(marker._tooltip._contentNode, 'hidden');
            L.DomUtil.removeClass(marker._path, 'hidden');
            L.DomUtil.addClass(marker._path, 'isolated-node');
          }
        });

        L.DomUtil.addClass(d.target._path, 'node-clicked');
      } else {
        this.markersLayer.eachLayer(marker => {
          L.DomUtil.addClass(marker._tooltip._contentNode, 'hidden');
          L.DomUtil.addClass(marker._path, 'hidden');
          L.DomUtil.removeClass(marker._path, 'node-clicked');
          L.DomUtil.removeClass(marker._path, 'isolated-node');

          const node = marker.options.props;
          if (d.target.options.props.nodeId === node.nodeId) {
            L.DomUtil.removeClass(marker._tooltip._contentNode, 'hidden');
            L.DomUtil.removeClass(marker._path, 'hidden');
            L.DomUtil.addClass(marker._path, 'isolated-node');
          }
        });
        L.DomUtil.addClass(d.target._path, 'node-clicked');
      }

    } else {
      this.edgesLayer.eachLayer(edge => {
        // L.DomUtil.removeClass(edge._path, 'hidden')
        L.DomUtil.removeClass(edge._path, 'isolated-link')
        L.DomUtil.removeClass(edge._path, 'highlight-link')
      });

      this.arrowLayer.eachLayer(arrow => {
        arrow.setPatterns(this.getPattern(arrow.options.props, []));
      });

      this.markersLayer.eachLayer(node => {
        L.DomUtil.removeClass(node._tooltip._contentNode, 'hidden');
        L.DomUtil.removeClass(node._path, 'hidden')
        L.DomUtil.removeClass(node._path, 'isolated-node')
        L.DomUtil.removeClass(node._path, 'node-clicked')
      });
    }
  }

  isolateNodesEdgesHover(hoverNode, d) {
    const isolated = L.DomUtil.hasClass(d.target._path, 'isolated-node');
    const { nodes, links } = this.filterNodesLinks(hoverNode);

    if (isolated) {
      this.edgesLayer.eachLayer(edge => {
        const isolatedLink = L.DomUtil.hasClass(edge._path, 'isolated-link');
        if (!isolatedLink && links.indexOf(edge.options.props) !== -1) {
          L.DomUtil.addClass(edge._path, 'isolated-hover-show');
        }
      });

      this.arrowLayer.eachLayer(arrow => {
        const classList = arrow.options.patterns[0].symbol.options.pathOptions.className.split(' ');
        const isolatedLink = classList.indexOf('isolated-link') !== -1;
        const edge = arrow.options.props;
        if (!isolatedLink && links.indexOf(edge) !== -1) {
          arrow.setPatterns(this.getPattern(edge, ['isolated-hover-show']));
        }
      });

      this.markersLayer.eachLayer(node => {
        const isolatedNode = L.DomUtil.hasClass(node._path, 'isolated-node');
        if (!isolatedNode && nodes.indexOf(node.options.props) !== -1) {
          L.DomUtil.addClass(node._path, 'isolated-hover-show');
        }
      });
    } else {
      this.edgesLayer.eachLayer(edge => {
        if (links.indexOf(edge.options.props) !== -1) {
          L.DomUtil.addClass(edge._path, 'highlight-link');
        }
      });

      this.arrowLayer.eachLayer(arrow => {
        const edge = arrow.options.props;
        if (links.indexOf(edge) !== -1) {
          arrow.setPatterns(this.getPattern(edge, ['highlight-link']));
        }
      });
    }
  }
}
