import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import * as d3 from 'd3';
import * as _ from 'lodash';

import {
  selectNode,
  selectEdge,
  hoverNode,
  clearSelection,
  fetchData
} from "../../redux/reducers/chartData/actions";

import {
  getData,
  getChartType,
  getSelFiltersArr,
  getCluster
} from "../../redux/reducers/chartData/selectors";

import NetworkChartClass from '../../charts/network_chart';
const networkChartClass = new NetworkChartClass();

const createCluster = (data, groupColumn, groupVals) => {
  const groupNodes = _.chain(data.nodes)
    .filter(k => {
      switch (k.labels[0].toLowerCase()) {
        case 'company':
          return (k.corp_group || []).filter(element => groupVals.includes(element)).length > 0
        case 'system':
          return (k.system_family || []).filter(element => groupVals.includes(element)).length > 0
        case 'platform':
          return (k.platform_family || []).filter(element => groupVals.includes(element)).length > 0
            || (k.model_version || []).filter(element => groupVals.includes(element)).length > 0
        default:
          return (k[groupColumn] || []).filter(element => groupVals.includes(element)).length > 0
      }

    })
    .groupBy(k => {
      let intersection;
      intersection = k[groupColumn].filter(element => groupVals.includes(element));
      return intersection[0];
    })
    .toPairs()
    .map((k, index) => {
      const excludeCols = [
        'id', 'nodeId', 'chart_type', 'index',
        'x', 'y', 'vy', 'vx', 'details', 'name', 'description'
      ];
      const keys = _.chain(k[1])
        .map(d => _.keys(d).filter(
          key => d[key] && excludeCols.indexOf(key) === -1)
        )
        .flatten()
        .uniq()
        .value();

      const obj = {
        nodeType: 'group',
        id: k[0],
        name: k[0],
        nodeId: index,
        size: k[1].length,
        nodeIds: _.uniq(k[1].map(d => d.nodeId)),
        ids: _.uniq(k[1].map(d => d.id)),
      };

      keys.forEach(key => {
        if (typeof (k[1][0][key]) === 'object') {
          obj[key] = _.chain(k[1])
            .map(d => d[key])
            .flatten()
            .uniq()
            .value();
        } else {
          obj[key] = _.chain(k[1])
            .map(d => d[key])
            .uniq()
            .value();
        }
      })

      return obj;
    })
    .value();

  const normalNodes = _.chain(data.nodes)
    .filter(k => (k[groupColumn] || [])
      .filter(element => groupVals.includes(element)).length === 0
    )
    .value();

  const resultNodes = [
    ...groupNodes,
    ...normalNodes
  ];

  const resultLinks = _.chain(data.links)
    .map(k => {
      // const test = resultNodes.find(d => {
      //   console.log(k.source, d.nodeIds, d.nodeIds.indexOf(k.source));
      //   return d.nodeIds.indexOf(k.source) !== -1;
      // });
      // console.log(k.source, test);
      return {
        source: resultNodes.find(d => {
          if (d.nodeType !== 'group') {
            return d.nodeId === k.source;
          } else {
            return d.nodeIds.indexOf(k.source) !== -1;
          }
        }),
        target: resultNodes.find(d => {
          if (d.nodeType !== 'group') {
            return d.nodeId === k.target;
          } else {
            return d.nodeIds.indexOf(k.target) !== -1;
          }
        }),
        properties: k.properties
      };
    })
    // .filter(k => k.source.nodeId !== k.target.nodeId)
    // .uniqBy(k => k.source + '-' + k.target)
    .value();

  return {
    nodes: resultNodes,
    links: resultLinks
  };
  // console.log(resultNodes);
  // console.log(resultLinks);
};

const NetworkChart = (props) => {
  const networkChart = React.useRef();

  const dispatch = useDispatch();
  const data = useSelector(state => getData(state));
  const chartType = useSelector(state => getChartType(state));
  const selFiltersArr = useSelector(state => getSelFiltersArr(state));
  const clusters = useSelector(state => getCluster(state));
  let groupColumn = props.type === 'labs' ? 'groups' : props.clusterValue || '';
  groupColumn = typeof (groupColumn) === 'string' ? groupColumn : groupColumn?.value;

  useEffect(() => {
    if (chartType === 'chart') {
      const width = networkChart.current.clientWidth;
      const height = networkChart.current.clientHeight;
      const svg = d3.select(networkChart.current);
      networkChartClass.initChart({
        svg, // networkChart.current,
        height,
        width
      });
      console.log('changed map')
      dispatch(fetchData(props.type, selFiltersArr, chartType));
    }

  }, [chartType]);

  useEffect(() => {
    if (data && chartType === 'chart') {
      const width = networkChart.current.clientWidth;
      const height = networkChart.current.clientHeight;
      const svg = d3.select(networkChart.current).select('svg');
      svg.attr('width', width);
      svg.attr('height', height);
      // console.log(data);
      const clusterData = createCluster(data, groupColumn, clusters.map(k => k.value));
      console.log(clusterData);

      networkChartClass.updateChart({
        data: clusterData, // data,
        width,
        height,
        dispatch,
        selectNode,
        hoverNode,
        selectEdge,
        clearSelection,
        tooltipRef: props.tooltipRef.current,
        positionType: 'chart',
      });
    }
  }, [data, dispatch, clusters])

  return (
    <svg className="network-chart" ref={networkChart}></svg>
  );
};


export default NetworkChart;