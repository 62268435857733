import * as React from "react";
import { Link } from 'react-router-dom';

const GRAPHS = [
  {
    caption: "Single platform, showing key integrators and suppliers (Foxhound UK)",
    src: require('../../assets/images/armored_pod.jpg')
  },
  {
    caption: "Single platform, showing key integrators and suppliers (DTI Black Widow)",
    src: require('../../assets/images/black_widow.jpg')
  },
  {
    caption: "Vehicle family with producers/suppliers, showing installed programs, recent wins and current competitions (Boxer 8x8)",
    src: require('../../assets/images/boxer_crv.jpg')
  },
  {
    caption: "Interactive table showing suppliers by role (Boxer 8x8)",
    src: require('../../assets/images/boxer_wcv_partners_suppliers.jpg')
  },
  {
    caption: "Vehicle program (recent win) showing full industrial team",
    src: require('../../assets/images/turret_production.jpg')
  },
  {
    caption: "Mobility suppliers connected to platforms served (Timoney and Texelis)",
    src: require('../../assets/images/vehicle_design.jpg')
  },
  {
    caption: "Interactive scorecard of APC/IFV production volume: installed, ordered, under competition",
    src: require('../../assets/images/tracked_wheeled.jpg')
  },
  {
    caption: "KMW vehicle tree with key production partners",
    src: require('../../assets/images/krauss_maffei.jpg')
  },
  {
    caption: "Czech land systems companies linked to vehicle programs (installed and opportunities)",
    src: require('../../assets/images/ascod.jpg')
  }
];

export class GraphGalleryComponent extends React.Component {
  render() {
    const style = { marginBottom: '20px' };
    const gallery = GRAPHS.map((item, index) => {
      return <div className="row" key={index}>
        <div className="col-12">
          <div style={style} className="img-thumbnail">
            <img alt="" src={item.src} className="img-fluid" />
            <div className="caption">
              <em>{item.caption}</em>
            </div>
          </div>
        </div>
      </div>
    });

    return (
      <section className="graph-gallery-component">

        <div className="za-body">
          <div className="za-header">
            <h1 className="za-title za-title-medium">
              GRAPH GALLERY
            </h1>
          </div>

          <div className="actionate">
            {gallery}

            <div className="row">
              <div className="col-sm-12">
                <Link to="/contact" className="btn btn-primary">
                  REQUEST DEMO
                  </Link>
                <Link to="/platform" className="btn btn-warning" style={{ marginLeft: '10px' }}>
                  Go To Graph
                  </Link>
              </div>
            </div>

          </div>
        </div>

      </section>
    );
  }
}