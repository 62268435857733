
import React from 'react';
import { useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsAltH } from '@fortawesome/free-solid-svg-icons'

import {
  getChartType
} from '../../redux/reducers/chartData/selectors';

import ChartDetailedInfo from './ChartDetailedInfo';
import NetworkChart from './NetworkChart';
import LeafletMapContainer from './LeafletMap';

import './NetworkChart.css';

const ChartComponent = (props) => {
  const edgeTooltip = React.useRef();
  const chartType = useSelector(state => getChartType(state));

  const showChart = () => {
    if (chartType === 'chart') {
      return <NetworkChart type={props.type} tooltipRef={edgeTooltip} clusterValue={props.clusterValue} />;
    } else {
      return <LeafletMapContainer tooltipRef={edgeTooltip} />;
    }
  };

  return (
    <div className="network-chart-container">

      <div className="path-tooltip" ref={edgeTooltip}>
        <div className="za-tooltip-title"></div>
        <div className="za-tooltip-sub-title"></div>
        <div className="za-tooltip-details">
          <span className="path-tooltip-source"></span>
          <FontAwesomeIcon icon={faArrowsAltH} size="lg" />
          <span className="path-tooltip-target"></span>
        </div>
      </div>

      <ChartDetailedInfo />
      {showChart()}
    </div>
  );
};

export default ChartComponent;