
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from "react-redux";

import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from 'reactstrap';

import MultiSelect from "react-multi-select-component";

import { fetchFilters, fetchData, saveSelectedFilters } from "../../redux/reducers/chartData/actions";
import { getFilters, getSelFilters, getChartType } from "../../redux/reducers/chartData/selectors";

import * as _ from 'lodash';

// MultiSelect Filters component
const MultiSelectComp = (props) => {
  const [value, setValue] = useState(props.selected[props.col.value]);
  const setReset = props.setReset;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  const selectValue = useCallback((val) => {
    setValue(val);
    props.selected[props.col.value] = val;
    props.setSelected(props.selected);
  }, [props]);

  useEffect(() => {
    if (props.reset) {
      selectValue([]);
      setReset(false);
    }
  }, [props.reset, setReset, selectValue]);

  const dropdownMenu = () => {
    let items = props.col.options.map((k, i) => {
      return <DropdownItem key={i}
        onClick={() => { selectValue([k]) }}>
        {k.label}
      </DropdownItem>;
    });
    // items = [];
    return <DropdownMenu>{items}</DropdownMenu>
  };

  const selectOpts = () => {
    if (props.col.value !== 'connection') {
      return <MultiSelect
        options={props.col.options}
        value={value}
        onChange={(val) => selectValue(val)}
        labelledBy={"Select"}
      />
    } else {
      return <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret>
          {value[0]?.value || 'Select Connection'}
        </DropdownToggle>
        {dropdownMenu()}
      </Dropdown>
    }
  };

  return (
    <>
      {selectOpts()}
    </>
  );
};

// create the dropdowns for each filter
const createFilterDropdowns = (opts, selected, setSelected, reset, setReset) => {
  const type = opts.type;
  const dropdowns = (row) => {
    return row
      .map((col, idx) => {
        col.options = _.sortBy(col.options, [k => k.label]);
        return <div className="row" key={idx} style={{ marginBottom: '10px' }}>
          <div className="col">
            {type !== 'connection' ? <label>{col.title}</label> : ''}
            <MultiSelectComp
              col={col}
              selected={selected}
              setSelected={setSelected}
              reset={reset}
              setReset={setReset} />
          </div>
        </div>
      });
  };

  return opts.filters.map((row, idx) => {
    return <>{dropdowns(row)}</>
  });
};

// setup the filters dropdown rows with headings
const filtersHTML = (opts, selected, setSelected, reset, setReset) => {
  const nodes = opts
    .filter(k => k.type === 'node')
    .map((row, idx) => {
      return <div className="col" key={idx}>
        <div className="row">
          <div className="col">
            <p style={{ fontSize: '16px', fontWeight: 'bold' }}>{row.heading}</p>
          </div>
        </div>
        {createFilterDropdowns(row, selected, setSelected, reset, setReset)}
      </div>
    });

  const rels = opts
    .filter(k => ['relationship'].indexOf(k.type) !== -1)
    .map((row, idx) => {
      return <>
        <div className="row" key={idx}>
          <div className="col">
            <p style={{ fontSize: '16px', fontWeight: 'bold' }}>{row.heading}</p>
          </div>
        </div>
        {createFilterDropdowns(row, selected, setSelected, reset, setReset)}
      </>
    });

  return <>
    {nodes}
    <div className="col" style={{ borderLeft: '1px solid #e1e1e1' }}>
      {rels}
    </div>
  </>
};

const filtersHTMLConnection = (opts, selected, setSelected, reset, setReset) => {
  const nodes = opts
    .filter(k => k.type === 'connection')
    .map((row, idx) => {
      return <div className="col-3" key={idx}>
        {createFilterDropdowns(row, selected, setSelected, reset, setReset)}
      </div>
    });

  return <>
    {nodes}
  </>
};


// set the filters data for multiselct and create object to hold all selected options
const createFilterObj = (obj, selFilters) => {
  const filterValueObj = {};
  const filterType = {};
  const filters = obj.map(row => {
    row.filters = row.filters.map(filterRow => {
      return filterRow.map(col => {
        col.options = col.options.map(k => {
          return {
            label: k,
            value: k
          };
        });
        filterValueObj[col.value] = selFilters[col.value] || []; // [col.options[0]];
        filterType[col.value] = row.type;
        return col;
      });
    });
    return row;
  });

  return {
    filterValueObj,
    filterType,
    filters
  };
};

const selectedFilters = (obj, filterType) => {
  const keys = Object.keys(obj);
  return keys.map(k => ({
    key: k,
    values: obj[k],
    type: filterType[k]
  }));
};

const FiltersMae = (props) => {
  const dispatch = useDispatch();

  const filtersData = useSelector(state => getFilters(state, props.type));
  const selFilters = useSelector(state => getSelFilters(state));
  const chartType = useSelector(state => getChartType(state));

  const [selected, setSelected] = useState({});
  const [filters, setFilters] = useState([]);
  const [filterType, setFilterType] = useState([]);
  const [reset, setReset] = useState(false);

  // on component load
  useEffect(() => {
    if (!filtersData) {
      dispatch(fetchFilters(props.type));
    }
  }, []);

  // on filters data change
  useEffect(() => {
    console.log(filtersData);
    if (filtersData) {
      const { filterValueObj, filterType, filters } = createFilterObj(
        JSON.parse(JSON.stringify(filtersData)),
        selFilters
      );
      setSelected(filterValueObj);
      setFilters(filters);
      setFilterType(filterType);
    }
  }, [filtersData])

  // on apply filters
  const applyFilters = () => {
    const selectedFiltersArr = selectedFilters(selected, filterType);
    dispatch(saveSelectedFilters(selected, selectedFiltersArr));
    dispatch(fetchData(props.type, selectedFiltersArr, chartType));
  };

  // on clear filters
  const clearFilters = () => {
    setReset(true);
    dispatch(saveSelectedFilters({}));
    dispatch(fetchData(props.type, [], chartType));
  };

  return (
    <section className="labs-filter">
      <div className="row" style={{ marginBottom: '10px' }}>
        <div className="col-3">
          <Button color="success" onClick={applyFilters}>Apply Filters</Button>
        </div>
        <div className="col-3">
          <Button color="danger" onClick={clearFilters}>Clear Filters</Button>
        </div>
        {filtersHTMLConnection(filters, selected, setSelected, reset, setReset)}
      </div>
      <hr />
      <div className="row">
        <div className="col-9" style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>Nodes</div>
        <div className="col-3" style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold' }}>Relationships</div>
      </div>
      <hr />
      <div className="row">
        {filtersHTML(filters, selected, setSelected, reset, setReset)}
      </div>
    </section>
  );
};

export default FiltersMae;