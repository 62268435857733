import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import jwt_decode from "jwt-decode";
// import NavbarComponent from './components/navbar/navbar';

const RouteGuard = ({ component: Component, ...rest }) => {

  function hasJWT() {
    //check user has JWT token
    const token = localStorage.getItem("zeta-token");
    if (!token) {
      return false;
    } else {
      try {
        const decoded = jwt_decode(token);
        console.log(decoded)
        return true;
      } catch (error) {
        console.log('invalid token')
        return false;
      }
    }
  }

  return (
    <Route {...rest}
      render={props => (
        hasJWT() ?
          <>
            {/* <NavbarComponent /> */}
            <Component {...props} />
          </>
          :
          <Redirect to={{ pathname: 'login' }} />
      )}
    />
  );
};

export default RouteGuard;