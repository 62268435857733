import * as React from "react";
import {
  AboutComponent,
  AboutSightLineComponent,
  AdminComponent,
  ContactComponent,
  GraphGalleryComponent,
  NewsAnalysisComponent,
  PoliciesComponent,
  UserGuideComponent,
  HomeComponent,
} from "./views";

import Login from './views/login'

import LabsGraph from "./views/graphs/labs_graph";
import MaeGraph from "./views/graphs/mae_graph";

const router = [
  {
    main: () => <AboutComponent />,
    name: "about",
    path: "/about",
    position: "right",
    pos_counter: 1,
    title: "Zeta Analytics"
  },
  {
    main: () => <AboutSightLineComponent />,
    name: "about-sightline",
    path: "/about-sightline",
    position: "left",
    pos_counter: 2,
    title: "About Sightline"
  },
  {
    main: () => <AdminComponent />,
    name: "admin",
    path: "/admin",
    position: "right",
    pos_counter: 4,
    title: "Admin"
  },
  {
    main: () => <ContactComponent />,
    name: "contact",
    path: "/contact",
    position: "left",
    pos_counter: 5,
    title: "Contact"
  },
  // {
  //   main: () => <LabsGraph />,
  //   name: "labs",
  //   path: "/labs",
  //   position: "left",
  //   pos_counter: 1,
  //   title: "Labs"
  // },
  {
    name: "graphs",
    path: "/graphs",
    position: "left",
    pos_counter: 1,
    routes: [
      {
        main: () => <LabsGraph />,
        name: "labs",
        parent: "graphs",
        path: "/graphs/labs",
        title: "Labs"
      },
      {
        main: () => <MaeGraph />,
        name: "ewim",
        parent: "graphs",
        path: "/graphs/mae",
        title: "Military Airborne Electronics"
      }
    ],
    title: "Graphs",
    type: "dropdown"
  },
  {
    main: () => <GraphGalleryComponent />,
    name: "graph_gallery",
    path: "/graph-gallery",
    position: "left",
    pos_counter: 3,
    title: "Graph Gallery"
  },
  // {
  //   main: () => <Login />,
  //   name: "login",
  //   path: "/login",
  //   position: "none",
  //   pos_counter: 0
  // },
  {
    main: () => <HomeComponent />,
    name: "home",
    path: "/",
    position: "none",
    pos_counter: 0
  },
  {
    main: () => <NewsAnalysisComponent />,
    name: "news_analysis",
    path: "/news-analysis",
    position: "left",
    pos_counter: 4,
    title: "News & Analysis"
  },
  {
    main: () => <PoliciesComponent />,
    name: "policies",
    path: "/policies",
    position: "right",
    pos_counter: 2,
    title: "Policies"
  },
  // {
  //   main: () => <PlatformComponent />,
  //   name: "platform",
  //   path: "/platform",
  //   position: "right",
  //   pos_counter: -1
  // },
  {
    main: () => <UserGuideComponent />,
    name: "user_guide",
    path: "/user-guide",
    position: "right",
    pos_counter: 3,
    title: "User Guide"
  }
];

export default router;