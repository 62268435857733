import * as d3 from 'd3';
import * as _ from 'lodash';

export const COLORS = {
  1: '#33a02c',
  2: '#1f78b4',
  3: '#e31a1c',
  4: '#ffff99',
  5: '#6a3d9a'
};

export const RADIUS = 10;
export const RADIUS_GROUP = 20;
export const COLOR_GROUP = '#dd3497';
export const COLORS_PLATFORM = 'rgb(169, 209, 142)';
export const COLORS_SYSTEM = 'rgb(253, 174, 107)';
export const COLORS_COMPANY = 'rgb(54, 144, 192)';

export const linkMouseOver = (d, tooltipRef, event) => {
  const tooltip = d3.select(tooltipRef);
  const details = tooltip.select('.za-tooltip-details');

  tooltip
    .style('top', `${event.offsetY}px`)
    .style('left', `${event.offsetX}px`)
    .style('display', 'block');

  if (
    (d.source.labels.indexOf('SYSTEM') !== -1 && d.target.labels.indexOf('PLATFORM') !== -1) ||
    (d.source.labels.indexOf('PLATFORM') !== -1 && d.target.labels.indexOf('SYSTEM') !== -1) ||
    (d.source.labels.indexOf('SYSTEM') !== -1 && d.target.labels.indexOf('SYSTEM') !== -1)
  ) {
    tooltip.select('.za-tooltip-title').html(
      `
        <b>Type:</b> ${d.properties.edgeType}<br/>
      `
    );
    tooltip.select('.za-tooltip-sub-title').html(
      `
        <b>Mission:</b> ${(d.properties.mission || []).join(', ')}<br/>
        <b>Op Status:</b> ${d.properties.operationalStatus}<br/>

      `
    );
  } else if (
    (d.source.labels.indexOf('COMPANY') !== -1 && d.target.labels.indexOf('SYSTEM') !== -1) ||
    (d.source.labels.indexOf('SYSTEM') !== -1 && d.target.labels.indexOf('COMPANY') !== -1) ||
    (d.source.labels.indexOf('COMPANY') !== -1 && d.target.labels.indexOf('COMPANY') !== -1)
  ) {
    tooltip.select('.za-tooltip-title').html(
      `
        <b>Type:</b> ${d.properties.edgeType}

      `
    );
    tooltip.select('.za-tooltip-sub-title').html('');
  } else {
    tooltip.select('.za-tooltip-title').html(`${d.properties.edgeType}`);
    tooltip.select('.za-tooltip-sub-title').html(`<b>Alliance Type:</b> ${(d.properties.allianceTypes || []).join(', ')}`);
  }

  details.select('.path-tooltip-source').text(d.source.id);
  details.select('.path-tooltip-target').text(d.target.id);
};

export const linkMouseOut = (tooltipRef) => {
  d3.select(tooltipRef).style('display', 'none');
};

export const filterClicked = (linksData, nodesData, clickedNode) => {
  const links = linksData.filter(k => {
    return k.source.nodeId === clickedNode.nodeId ||
      k.target.nodeId === clickedNode.nodeId;
  });

  let nodes = _.chain(links)
    .map(k => [k.source.nodeId, k.target.nodeId])
    .flatten()
    .uniq()
    .map(id => nodesData.find(k => k.nodeId === id))
    .value();

  if(nodes.length === 0) {
    nodes = nodesData.filter(k => k.nodeId === clickedNode.nodeId);
  }

  return { nodes, links }
};

export const filterConcentricCircle = (d, value, column, chartType) => {
  let condition = false;
  if (typeof (d[column]) === 'string') {
    condition = d.chart_type === chartType && d[column] === value;
  } else {
    condition = d.chart_type === chartType && d[column]
      && d[column].indexOf(value) !== -1;
  }
  return condition;
};