import * as React from "react";
import { Link } from 'react-router-dom';

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

const items = [
  {
    src: require('../../assets/images/chart_view.jpg'),
    altText: 'chart view',
    caption: '',
    header: ''
  },
  {
    src: require('../../assets/images/armored_pod.jpg'),
    altText: 'armored pod',
    caption: '',
    header: ''
  },
  {
    src: require('../../assets/images/black_widow.jpg'),
    altText: 'black widow',
    caption: '',
    header: ''
  },
  {
    src: require('../../assets/images/jais_wtv.jpg'),
    altText: 'black widow',
    caption: '',
    header: ''
  },
  {
    src: require('../../assets/images/boxer_crv.jpg'),
    altText: 'boxer crv',
    caption: '',
    header: ''
  },
  {
    src: require('../../assets/images/turret_production.jpg'),
    altText: 'turret production',
    caption: '',
    header: ''
  },
  {
    src: require('../../assets/images/local_production.jpg'),
    altText: 'turret production',
    caption: '',
    header: ''
  },
  {
    src: require('../../assets/images/vehicle_design.jpg'),
    altText: 'vehicle design',
    caption: '',
    header: ''
  },
  {
    src: require('../../assets/images/bae_kongsberg.jpg'),
    altText: 'bae kongsberg',
    caption: '',
    header: ''
  },
  {
    src: require('../../assets/images/krauss_maffei.jpg'),
    altText: 'kraus maffei',
    caption: '',
    header: ''
  },
  {
    src: require('../../assets/images/boxer_wcv_partners_suppliers.jpg'),
    altText: 'boxer wcv partners and suppliers',
    caption: '',
    header: ''
  },
  {
    src: require('../../assets/images/tracked_wheeled.jpg'),
    altText: 'tracked and wheeled',
    caption: '',
    header: ''
  },
];

export class HomeComponent extends React.Component {

  animating;

  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state['activeIndex'] === items.length - 1 ? 0 :
      this.state['activeIndex'] + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state['activeIndex'] === 0 ? items.length - 1 :
      this.state['activeIndex'] - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {

    const activeIndex = this.state['activeIndex'];

    const slides = items.map((item) => {
      return (
        <CarouselItem
          onExiting={this.onExiting}
          onExited={this.onExited}
          key={item.src}
        >
          <img className="img-fluid" style={{ width: '100%', height: '450px' }} src={item.src} alt={item.altText} />
          <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
        </CarouselItem>
      );
    });

    const myCarousel = <Carousel
      activeIndex={activeIndex}
      next={this.next}
      previous={this.previous}
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
    </Carousel>

    return <section className="home-component">
      <div className="za-body">
        <div className="za-header">
          <h1 className="za-title za-title-medium">SIGHTLINE</h1>
          <div className="za-sub-title">
            <p>Visualizing the global defense industry</p>
          </div>
        </div>

        <div className="actionate">
          <div className="row">
            <div className="col-4 we-do">
              <h3 className="what-we-do"><span className="glyphicon glyphicon-filter" />Distill.</h3>
              <p className="how-we-do">Filter the market to the relationships that matter. Make it digestible.</p>
            </div>
            <div className="col-4 we-do">
              <h3 className="what-we-do"><span className="glyphicon glyphicon-road" />Discover.</h3>
              <p className="how-we-do">Follow the connections among players and platforms. Pinpoint the market drivers, threats and opportunities.</p>
            </div>
            <div className="col-4 we-do">
              <h3 className="what-we-do"><span className="glyphicon glyphicon-retweet" />Deploy.</h3>
              <p className="how-we-do">Turn research into results, quickly and cost-effectively. Apply insights to tactics and strategy.</p>
            </div>
          </div>
        </div>

        <div className="actionate">
          <div className="row justify-content-center">
            <div className="col-10">
              {myCarousel}
            </div>
          </div>
        </div>

        <div className="actionate">
          <div className="row">
            <div className="col-12">
              <h2 className="za-about-subject">VISUAL ANALYSIS OF DEFENSE INDUSTRIAL RELATIONSHIPS</h2>
              <div className="za-about-content">
                <p>Sightline is a unique visualization tool that models <i>connections</i> in the global defense industry: the supplier relationships, industrial teams, joint ventures and technology partnerships employed in the pursuit and defense of market share. Its network-graph interface allows you to find and cross-reference company and platform information much more effectively than traditional, stovepiped databases or handbooks.</p>
                <p>Sightline is not another market forecasting product. Taking a complementary tack to your budget- and revenue-based outlook, Sightline helps your business development leaders, M&A team and product planners investigate the shifting relationships and dependencies that shape the market. The situational awareness it provides is as relevant for tactical decision-making as it is for strategic planning.</p>
                <p>At the basic level, Sightline serves as an interactive reference guide. Look up hundreds of combat platforms with embedded technical and program summaries. Get an instant picture of a manufacturer’s global installed base or a system’s family tree of versions and variants. Filter by program status to see the opportunities pipeline or by customer to view current and planned fleets.</p>
                <p>In more advanced applications, Sightline is a predictive tool that can reveal your competitors’ growth and product strategies, identify critical nodes in supply chains and illuminate high-potential targets for alliance or acquisition.</p>
                <p>Our first visualization, <i>Military Ground Vehicles,</i> is available now.</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <h2 className="za-about-subject">GET YOUR SIGHTLINE</h2>
              <div className="za-about-content">
                <p>Register to get access to the free trial version of Sightline, <i>Military Ground Vehicles: Producers-Platforms.</i> This visualization shows producers-integrators (OEMs, manufacturing & assembly partners, etc.) connected to vehicle platforms across three classes: wheeled combat vehicles, tracked combat vehicles and wheeled tactical vehicles. Use it as a fast reference tool to navigate a universe of approx. 250 vehicle families totaling over 900 individual vehicle programs. Filter and customize the visualization by platform family, platform class, producer, user country, competitive opportunities, etc.</p>
                <p>The trial version also includes a subset of our <i>Alliance</i> graph showing corporate organizational structures: parent-subsidiary relationships, joint ventures and some recent M&A activity.</p>
                <p>Interested in the full version of <i>Military Ground Vehicles? </i> Use the registration form to request subscription information. The full version adds hundreds of supplier connections including turrets and other weapon systems; mobility systems; armor and other protection systems; and electronics and sensors. Prices start as low as $400 for a single-user, web-only annual subscription.</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Link to="contact" className="btn btn-primary">
                GET ACCESS
              </Link>
            </div>
          </div>

        </div>{/* za-body */}

      </div> {/* za-body */}
    </section>;
  }
}