import * as React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import { Link } from "react-router-dom";
import router from '../../Router';
import { withRouter } from "react-router-dom";

import './navbar.css';

class NavbarComponent extends React.Component {
  // private appName = 'Zeta Analytics';
  logo = require('../../assets/zeta_logo.svg');

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.logout = this.logout.bind(this);
    this.getNavItems = this.getNavItems.bind(this);
    this.state = {
      isOpen: false
    };
    this.props = props;
    this.login = this.props.location.pathname.indexOf('login') !== -1;
  }
  toggle() {
    this.setState({
      isOpen: !this.state["isOpen"]
    });
  }

  getNavItems(position) {
    const routes = router
      .filter(d => d["position"] === position)
      .filter(route => route['pos_counter'] !== -1)
      .sort((a, b) => a["pos_counter"] - b["pos_counter"]);

    return routes
      .map(route => {
        if (route['type'] !== 'dropdown') {
          return <NavItem key={route["name"]}>
            <Link to={route["path"]} className="nav-link">
              {route["title"]}
            </Link>
          </NavItem>;
        } else {
          const dropdown = <UncontrolledDropdown nav={true} inNavbar={true} key={route["name"]}>
            <DropdownToggle nav={true} caret={true}>
              {route["title"]}
            </DropdownToggle>
            <DropdownMenu right={true} className="nav-dropdown-menu">
              {route["routes"].map((item) => {
                return <DropdownItem key={item["name"]}>
                  <Link to={item["path"]}>
                    {item["title"]}
                  </Link>
                </DropdownItem>;
              })}
            </DropdownMenu>
          </UncontrolledDropdown>;
          return dropdown;
        }
      });
  }

  logout(e) {
    localStorage.removeItem('zeta-token')
    this.props.history.push('/login');
  };

  render() {
    const navItemsLeft = this.getNavItems('left');
    const navItemsRight = this.getNavItems('right');
    this.login = this.props.location.pathname.indexOf('login') !== -1;

    return <div>
      <Navbar color="light" light={true} expand="md">
        <Link to="/" className="navbar-brand navbar-brand-link">
          <img alt="" className="img-fluid" src={this.logo} />
        </Link>
        {!this.login ? <>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state["isOpen"]} navbar={true}>
            <Nav navbar={true}>{navItemsLeft}</Nav>
            <Nav className="ml-auto" navbar={true}>
              {navItemsRight}
              <NavItem>
                <div className="nav-link" onClick={this.logout}>Logout</div>
              </NavItem>
            </Nav>
          </Collapse></>
          : ''}
      </Navbar>
    </div>;
  }
}

export default withRouter(NavbarComponent);