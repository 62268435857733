import * as React from "react";

export class UserGuideComponent extends React.Component {
  render() {
    return (
      <section className="user-guide-component">

        <div className="za-body">
          <div className="za-header">
            <h1 className="za-title za-title-medium">USER GUIDE</h1>
          </div>
          <div className="actionate">
            <div className="row">
              <div className="col-12">
                <embed src={require('../../assets/images/user_guide.pdf')} width="100%" height="1100" />
              </div>
            </div>
          </div>
        </div>

      </section>
    );
  }
}