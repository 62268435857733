import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import {
  hoverNode,
  clearSelection,
} from "../../redux/reducers/chartData/actions";

import {
  getSelectedEdge,
  getSelectedNode,
  getSelectedType,
  getHoveredNode,
  getData
} from '../../redux/reducers/chartData/selectors';

import './chart-info.css';

const CloseButton = styled.button`
    float: right;
    font-size: 21px;
    font-weight: bold;
    line-height: 0.6;
    color: rgb(0, 0, 0);
    text-shadow: rgb(255, 255, 255) 0px 1px 0px;
    opacity: 0.2;
    cursor: pointer;
    border: none;
  `;

// show the info when node or link is clicked
const ChartDetailedInfo = (props) => {

  const dispatch = useDispatch();
  const type = useSelector(state => getSelectedType(state));
  const node = useSelector(state => getSelectedNode(state));
  const edge = useSelector(state => getSelectedEdge(state));
  const hoverNode = useSelector(state => getHoveredNode(state));
  const data = useSelector(state => getData(state));

  const [showNodeInfo, setShowNodeInfo] = useState(false);
  const [showHoverNodeInfo, setShowHoverNodeInfo] = useState(false);
  const [showEdgeInfo, setShowEdgeInfo] = useState(false);

  const [edgeSource, setEdgeSource] = useState({});
  const [edgeTarget, setEdgeTarget] = useState({});

  // useEffect(() => {
  //   if (node.id || edge.id) {
  //     setShowNodeInfo(true);
  //     setShowEdgeInfo(true);
  //   } else {
  //     setShowNodeInfo(false);
  //     setShowEdgeInfo(false);
  //   }
  // }, [node, edge])

  useEffect(() => {
    switch (type) {
      case 'node':
        setShowHoverNodeInfo(false);
        setShowNodeInfo(true);
        setShowEdgeInfo(false);
        break;
      case 'hover-node':
        setShowHoverNodeInfo(true);
        setShowNodeInfo(false);
        setShowEdgeInfo(false);
        break;
      case 'edge':
        setShowHoverNodeInfo(false);
        setShowNodeInfo(false);
        setShowEdgeInfo(true);
        break;
      default:
        setShowHoverNodeInfo(false);
        setShowNodeInfo(false);
        setShowEdgeInfo(false);
        break;
    }
  }, [type])


  useEffect(() => {
    if (data && data.nodes) {
      const source = data.nodes.find(k => k.nodeId === edge.sourceNodeId);
      setEdgeSource(source);
      const target = data.nodes.find(k => k.nodeId === edge.targetNodeId);
      setEdgeTarget(target);
    }
  }, [edge, data])

  const titleText = () => {
    if (hoverNode && hoverNode.id) {
      return <span className="company-name">{hoverNode.name || hoverNode.id}</span>

    } else if (node && node.id) {
      return <span className="company-name">{node.name || node.id}</span>

    } else {

      const allianceTypes = edge.allianceTypes ? edge.allianceTypes.join(', ') : '';
      // const labels = edge.labels ? edge.labels.join(', ') : '';

      return [
        <p className="relationship-category" key={0}>
          {allianceTypes}
        </p>,
        <p className="relationship-label" key={1}>
          {edge.edgeType === 'NA' ? '' : edge.edgeType}
        </p>
      ];
    }
  };

  const fieldsText = () => {
    let nodefields = [
      { title: 'AFFILIATION', value: 'affiliation' },
      { title: 'THREAT LEVEL', value: 'threat_level' },
      { title: 'THREAT CATEGORY', value: 'threat_category' },
      { title: 'LOCATION', value: 'location' },
      { title: 'SOURCE', value: 'source' },
      { title: 'LINK', value: 'link' },
      { title: 'DESCRIPTION', value: 'description', type: 'html' },
      { title: 'NODE IDs', value: 'ids' },
    ];

    const edgeFields = [
      { title: 'SOURCE', value: 'sourceNodeId' },
      { title: 'TARGET', value: 'targetNodeId' },
      { title: 'DETAILS', value: 'details', type: 'html' },
      { title: 'DESCRIPTION', value: 'description', type: 'html' },
      // { title: 'LABELS', value: 'labels' },
      // { title: 'TECHNOLOGY FOCUS', value: 'techFocus' },
      { title: 'EDGE TYPE', value: 'edgeType' },
      { title: 'LINKS', value: 'links' },
      { title: 'MISSION', value: 'mission' },
      { title: 'OPERATIONAL STATUS', value: 'operationalStatus' },
    ];

    if (
      (node.labels && node.labels[0] === 'SYSTEM') ||
      (hoverNode.labels && hoverNode.labels[0] === 'SYSTEM')
    ) {
      nodefields = [
        { title: 'PROGRAM STATUS', value: 'program_status' },
        { title: 'PRIME-OEM', value: 'prime_oem' },
        { title: 'DOMAIN', value: 'domain' },
        { title: 'DESIGN ORIGIN', value: 'design_origin' },
        { title: 'SYSTEM FAMILY', value: 'system_family' },
        { title: 'SYSTEM TYPE', value: 'system_type' },
        { title: 'DETAILS', value: 'details' },
        { title: 'CUSTOMERS', value: 'customers' },
      ];
    } else if (
      (node.labels && node.labels[0] === 'PLATFORM') ||
      (hoverNode.labels && hoverNode.labels[0] === 'PLATFORM')
    ) {
      nodefields = [
        { title: 'PRIME-INTEGRATOR', value: 'prime_integrator' },
        { title: 'TYPE', value: 'type' },
        { title: 'PLATFORM FAMILY', value: 'platform_family' },
        { title: 'CUSTOMERS', value: 'customers' },
        { title: 'DETAILS', value: 'details' },
      ];
    } else if (
      (node.labels && node.labels[0] === 'COMPANY') ||
      (hoverNode.labels && hoverNode.labels[0] === 'COMPANY')
    ) {
      nodefields = [
        { title: 'COMPANY TYPE', value: 'company_type' },
        { title: 'COUNTRY', value: 'country' },
        { title: 'REGION', value: 'region' },
        { title: 'CORP GROUP', value: 'corp_group' },
        { title: 'DESCRIPTION', value: 'description' },
        { title: 'MARKET SEGMENT', value: 'market_segment' },
        { title: 'STAKEHOLDER', value: 'stakeholders' },
      ];
    }

    // console.log(edgeSource, edgeTarget);

    const showFields = (obj, fields) => {
      return fields
        .filter(field => obj[field.value])
        .map((field, idx) => (
          <li className="field-detail" key={idx}>
            <div className="detail-header">{field.title}</div>
            {field.type === 'html'
              ? <div className="detail-body" dangerouslySetInnerHTML={{ __html: obj[field.value] }} />
              : <div className="detail-body">
                {typeof obj[field.value] === 'object' && obj[field.value].length
                  ? obj[field.value].join(', ')
                  : (
                    field.value === 'sourceNodeId' && edgeSource
                      ? (edgeSource.name || edgeSource.id)
                      : (field.value === 'targetNodeId' && edgeTarget ? (edgeTarget.name || edgeTarget.id) : obj[field.value]))}
              </div>
            }
          </li>
        ));
    };

    if (hoverNode && hoverNode.id) {
      return <ul className="field-list list-unstyled">
        {showFields(hoverNode, nodefields)}
      </ul>
    } else if (node && node.id) {
      return <ul className="field-list list-unstyled">
        {showFields(node, nodefields)}
      </ul>
    } else {
      return <ul className="field-list list-unstyled">
        {showFields(edge, edgeFields)}
      </ul>
    }
  };

  const closeBtn = () => {
    dispatch(clearSelection());
  };

  const showDetail = () => {
    if (['node', 'hover-node'].indexOf(type) !== -1) {
      return <div className="company-detail">
        <div className="detail-title">
          <CloseButton className="close-btn" onClick={closeBtn}>x</CloseButton>
          {titleText()}
        </div>
        <div className="detail-fields">
          {fieldsText()}
        </div>
      </div>;
    } else {
      return <div className="relationship-detail">
        <div className="detail-title">
          <CloseButton className="close-btn" onClick={() => dispatch(clearSelection())}>x</CloseButton>
          {titleText()}
        </div>
        <div className="detail-fields">
          {fieldsText()}
        </div>
      </div>
    }
  };

  return (
    <>
      <div className={`hover-node-info detailed-info ${showHoverNodeInfo ? 'visible' : 'hidden'}`}>
        {showDetail()}
      </div>
      <div className={`node-info detailed-info ${showNodeInfo ? 'visible' : 'hidden'}`}>
        {showDetail()}
      </div>
      <div className={`edge-info detailed-info ${showEdgeInfo ? 'visible' : 'hidden'}`}>
        {showDetail()}
      </div>
    </>
  );
};

export default ChartDetailedInfo;