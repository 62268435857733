export const getChartDataState = store => store.chartData;

export const getFetchingData = store => store.chartData.fetchingData;

export const getData = store => store.chartData.data.data;

export const getFilters = (store, type) => store.chartData.filters[type];
export const getClusters = store => store.chartData.clusters;

export const getSelFilters = store => store.chartData.selFilters;
export const getSelFiltersArr = store => store.chartData.selFiltersArr;

export const getSelectedNode = store => store.chartData.selectedNode;
export const getHoveredNode = store => store.chartData.hoveredNode;

export const getSelectedEdge = store => store.chartData.selectedEdge;

export const getSelectedType = store => store.chartData.selectedType;
export const getCluster = store => store.chartData.selCluster;

export const getChartType = store => store.chartData.chartType;

export const getMap = store => store.chartData.map;