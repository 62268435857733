import { createStore, applyMiddleware } from "redux";
import { combineReducers } from "redux";
import ReduxThunk from 'redux-thunk';

// import ApiService from "../services/api.service";

// import reducers
import todos from "./reducers/todos";
import visibilityFilter from "./reducers/visibilityFilter";
import chartData from "./reducers/chartData";

// combine reducers
const rootReducer = combineReducers({
  todos,
  visibilityFilter,
  chartData
});

export default createStore(
  rootReducer,
  applyMiddleware(ReduxThunk)
);