import ApiService from "../../../services/api.service";
import { useHistory } from "react-router-dom";

const apiService = new ApiService();

export const FETCH_DATA = "FETCH_DATA";
export const FETCH_DATA_START = "FETCH_DATA_START";
export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const FETCH_DATA_ERROR = "FETCH_DATA_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const SAVE_DATA = "SAVE_DATA";
export const SAVE_FILTERS = "SAVE_FILTERS";
export const SAVE_CLUSTERS = "SAVE_CLUSTERS";
export const SELECTED_FILTERS = "SELECTED_FILTERS";
export const SAVE_MAP = "SAVE_MAP";
export const SET_CHART_TYPE = "SET_CHART_TYPE";

export const SELECT_NODE = "SELECT NODE";
export const HOVER_NODE = "HOVER_NODE";
export const SELECT_EDGE = "SELECT EDGE";
export const CLEAR_SELECTION = "CLEAR SELECTION";
export const SET_CLUSTER = "SET_CLUSTER";

export const fetchDataStart = () => ({
  type: FETCH_DATA_START
});

export const fetchDataError = (error) => ({
  type: FETCH_DATA_ERROR,
  payload: { error }
});

export const fetchDataSuccess = (error) => ({
  type: FETCH_DATA_SUCCESS
});

export const clearError = () => ({
  type: CLEAR_ERROR
});

export const saveData = (data) => ({
  type: SAVE_DATA,
  payload: { data }
});

export const saveFilters = (payload, chartType) => ({
  type: SAVE_FILTERS,
  payload,
  chartType
});

export const saveClusters = (payload) => ({
  type: SAVE_CLUSTERS,
  payload
});

export const saveSelectedFilters = (payload, filtersArr) => ({
  type: SELECTED_FILTERS,
  payload,
  filtersArr
});

export const saveMap = (map) => ({
  type: SAVE_MAP,
  payload: map
});

export const setChartType = (map) => ({
  type: SET_CHART_TYPE,
  payload: map
});


export const clearSelection = () => ({
  type: CLEAR_SELECTION
});

export const selectNode = (payload) => ({
  type: SELECT_NODE,
  payload
});

export const hoverNode = (payload) => ({
  type: HOVER_NODE,
  payload
});

export const selectEdge = (payload) => ({
  type: SELECT_EDGE,
  payload
});

export const setCluster = (payload) => ({
  type: SET_CLUSTER,
  payload
});
/* API CALLS */

export const fetchData = (type, filters = [], chartType = 'chart') => {
  return async (dispatch) => {
    dispatch(fetchDataStart());
    try {

      // filter for relationship label based on chart type
      const filts = JSON.parse(JSON.stringify(filters));

      if (type === 'labs') {
        if (chartType === 'map') {
          filts.push({
            key: 'label',
            type: 'relationship',
            values: [
              { label: 'Map', value: 'Map' }
            ]
          });
        } else {
          filts.push({
            key: 'label',
            type: 'relationship',
            values: [
              { label: 'Network', value: 'Network' }
            ]
          });
        }
      }

      // console.log('~~~~~', filts, chartType);

      const data = await apiService.getData(type, filts);
      console.log(data);
      dispatch(saveData(data));
      dispatch(fetchDataSuccess());
    } catch (error) {
      dispatch(fetchDataError(error));
    }
  }
};

export const fetchFilters = (type) => {
  return async (dispatch) => {
    dispatch(fetchDataStart());
    try {
      const data = await apiService.getFilters(type);
      dispatch(saveFilters(data, type));
      dispatch(fetchDataSuccess());
    } catch (error) {
      dispatch(fetchDataError(error));
    }
  }
};

export const fetchClusters = (dbType, column) => {
  return async (dispatch) => {
    dispatch(fetchDataStart());
    try {
      const data = await apiService.getClusters(dbType, column);
      dispatch(saveClusters(data));
      dispatch(fetchDataSuccess());
    } catch (error) {
      dispatch(fetchDataError(error));
    }
  }
};

export const loginUser = async (username, password) => {
  const data = await apiService.loginUser(username, password);
  localStorage.setItem('zeta-token', data.token);
  return data;
};
