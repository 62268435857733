import * as React from "react";

import PolicyFeb18 from "./policy_feb18";

export class PoliciesComponent extends React.Component {
  render() {
    return <section className="policies-component">
      <PolicyFeb18 />
    </section>;
  }
}