
import React, { useEffect, useRef } from 'react';
// import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { useSelector, useDispatch } from "react-redux";
import './chart-info.css';

import {
  selectNode,
  selectEdge,
  clearSelection,
  saveMap,
  fetchData
} from "../../redux/reducers/chartData/actions";

import {
  getData,
  getChartType,
  getSelFiltersArr
} from "../../redux/reducers/chartData/selectors";


import 'leaflet/dist/leaflet.css';
import NetworkMapClass from '../../charts/network_map';

const networkMapClass = new NetworkMapClass();

const LeafletMapContainer = (props) => {
  const networkMap = useRef();


  const dispatch = useDispatch();
  const data = useSelector(state => getData(state));
  const chartType = useSelector(state => getChartType(state));
  const selFiltersArr = useSelector(state => getSelFiltersArr(state));

  const opts = {
    lat: 39.028115,
    lng: -77.135448,
    zoom: 6,
  };

  useEffect(() => {
    console.log('component did mount')
    // console.log(networkMap.current);
    const map = networkMapClass.initMap(networkMap.current, opts);
    dispatch(saveMap(map));
  }, []);

  useEffect(() => {
    if (chartType === 'chart') {
      networkMapClass.stopChartSimulation();
    }

    if (chartType === 'map') {
      dispatch(fetchData('labs', selFiltersArr, chartType));
    }
  }, [chartType])

  // useEffect((d) => {
  //   console.log('###', props.data)
  // }, [props.data]);

  useEffect(() => {
    console.log(data);
    if (data && chartType === 'map') {
      networkMapClass.updateMap({
        data,
        // width: WIDTH,
        // height: HEIGHT,
        dispatch,
        selectNode,
        selectEdge,
        clearSelection,
        tooltipRef: props.tooltipRef.current,
      });
    }
  }, [data, dispatch])



  return (
    <div className="network-map" ref={networkMap}></div>
  )

};

export default LeafletMapContainer; 