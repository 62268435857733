import * as React from "react";

export default class PolicyFeb18 extends React.Component {
  render() {
    return <div className="za-body">
      <div className="za-header">
        <h1 className="za-title za-title-medium">TERMS & CONDITIONS</h1>
      </div>
      <div className="actionate za-about-content">
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">TERMS & CONDITIONS</h2>
            <p>
              Welcome to www.zetanalytics.com, a website owned and operated by
              Zeta Analytics LLC, a Massachusetts company ("Zeta Analytics,"
              "we" or "us"). The following page details the terms and
              conditions by which you may use our web site, Application
              Program Interface (“Sightline”) and any other web services or
              applications owned, controlled, or offered by Zeta Analytics
              available through www.zetanalytics.com (collectively, the
              "Site") whether as a guest, registered demo user or registered
              licensed user. By accessing or using the Site, or by
              acknowledging your consent through any "clickwrap" interface
              required to access any portions of Site or the Sightline tool,
              you represent that you have read, understood and agree to be
              bound by the following terms of use (the "Terms of Use
              Agreement" or "Agreement") and our privacy policy incorporated
              herein ("Privacy Policy"). Please read the Agreement carefully
              before proceeding and using the Site. If you do not want to
              agree to this Agreement or the Privacy Policy, you must not
              access or use the Site.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Acceptance of Agreement</h2>
            <p>
              Your use of the Site constitutes your acceptance of the terms
              and conditions outlined in this Terms of Use Agreement. This
              Terms of Use Agreement, together with any policies posted on our
              website, are collectively referred to herein as the "Site
              Policies." The Terms of Use Agreement constitutes the entire and
              only agreement between us and you concerning the subject matter
              of the Terms of Use Agreement and supersedes all prior or
              contemporaneous agreements, representations, warranties and
              understandings concerning the subject matter of the Terms of Use
              Agreement. We reserve the right to make changes to our Site,
              Content (as defined below), Services (as defined below), this
              Terms of Use Agreement and the Site Policies from time to time
              at our sole discretion. We may make such revisions without prior
              notice to you. Unless otherwise provided in such revision, any
              revision to our Site, this Terms of Use Agreement or the Site
              Policies, or part thereof, will take effect when they are
              posted.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Electronic Communications</h2>
            <p>
              When you visit www.zetanalytics.com or send e-mails to us, you
              are communicating with us electronically. You acknowledge that
              you can access information that is provided electronically, and
              you consent to receive communications from us electronically. We
              will communicate with you by e-mail or by posting notices on
              this website. You agree that all agreements, notices,
              disclosures and other communications that we provide to you
              electronically satisfy any legal requirement that such
              communications be in writing.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Our Services</h2>
            <p>
              We provide you with the opportunity to access and use
              interactive market tools and analyses, provide information about
              companies, programs and products on the Site and use other
              services that may be provided from time to time on the Site (the
              "Services"). Notwithstanding anything contained herein to the
              contrary, we reserve the right to withdraw or amend this Site,
              and any Service or material we provide on the Site, in our sole
              discretion without notice. We will not be liable if for any
              reason all or any part of the Site is unavailable at any time or
              for any period. From time to time, we may restrict access to
              some parts of the Site, or the entire Site, to users, including
              registered users.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Registration</h2>
            <p>
              To register for our online Services, you must have a valid email
              address. When you register on our Site for the use of our
              Services, you agree to provide true, accurate, current and
              complete information about yourself as required by our
              registration application, and to maintain and promptly update
              the information provided by you in order to keep it true,
              accurate, current and complete. You further agree that you are
              not in violation of any trade secret or confidentiality
              agreement and otherwise have the right to provide all
              information you submit to the Site. You further agree that you
              are creating the account for yourself, and not for a third
              party. In addition, you agree that you will comply with all
              policies of the Site, including this Terms of Use Agreement. You
              agree to provide accurate data and information when using the
              Site. You agree not to use the Services or access the Site with
              malicious intent. You also represent that you are not a person
              barred from receiving our Services under the laws of the United
              States or any other applicable jurisdiction. If you do not
              comply with the foregoing conditions or any of the terms and
              conditions set forth in this Agreement, or we reasonably suspect
              that information provided by you is in violation of the
              foregoing conditions, you must not access or use the Site and we
              have the right to suspend or terminate your account and refuse
              any and all current or future use of our Services (or any
              portion thereof).
            </p>
            <p>
              We assume no responsibility for the timeliness, deletion,
              mis-delivery or failure to store any user communications or
              personalization settings. You are responsible for obtaining
              access to our Services, and that access may involve third-party
              fees (such as Internet service provider or airtime charges. In
              addition, you must provide and are responsible for all equipment
              necessary to access our Services.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Account Security</h2>
            <p>
              If you choose, or are provided with, a user name, password or
              any other piece of information as part of our security
              procedures, you must treat such information as confidential, and
              you must not disclose it to any other person or entity. You also
              acknowledge that your account is personal to you and agree not
              to provide any other person with access to this Site or portions
              of it using your user name, password or other security
              information. You agree to notify us immediately of any
              unauthorized access to or use of your user name or password or
              any other breach of security. You also agree to ensure that you
              exit from your account at the end of each session. You should
              use particular caution when accessing your account from a public
              or shared computer so that others are not able to view or record
              your password or other personal information.
            </p>
            <p>
              We have the right to disable any user name, password or other
              identifier, whether chosen by you or provided by us, at any time
              in our sole discretion for any or no reason, including if, in
              our opinion, you have violated any provision of this Agreement.
              We reserve the right to refuse service, terminate accounts,
              remove or edit content, or cancel orders at our sole discretion.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Nontransferable</h2>
            <p>
              Your right to use our website is not transferable or assignable.
              Any password or right given to you to obtain information or
              access the Site is not transferable or assignable and any
              attempt to transfer your rights to another party may result in
              suspension or termination of your account.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Ownership of Data</h2>
            <p>
              When you submit information to us or respond to survey questions
              through the Site, you irrevocably grant, transfer and convey to
              us all right, title and interest in and to the materials and
              content provided, including all intellectual property rights
              therein. You agree we have the unlimited and unconditional right
              to distribute, exploit and use in perpetuity, in any media and
              by any means now known or hereafter devised, any materials,
              photographs, statements, compositions, submissions, responses to
              surveys and questions, trends, information regarding the markets
              you follow, biographies, and/or other content, whether written,
              spoken, or otherwise expressed by you (collectively, the
              "Published Information") provided by you on the Site. We shall
              have the right to use, store, market, promote, edit,
              incorporate, distribute, reproduce, display, perform, transmit,
              advertise, re-use, re-distribute, and re-transmit the Published
              Information at our own option and using any method at our
              discretion without any payment or other obligation to you. We
              shall also have the right to use the Published Information in
              order to promote our products and services. You hereby waive any
              right to inspect or approve uses of the Published Information,
              and waive any moral rights you may have in the Published
              Information. Without limitation, you hereby waive any rights to
              privacy, publicity and defamation, whether granted by statute or
              common law, that you may have in connection with the Published
              Information. You agree that we shall own all right, title and
              interest in and to the Published Information, any works derived
              therefrom, including any related copyrights. To the extent
              permissible by law, the Published Information shall be deemed a
              "work made for hire" for us pursuant to the US Copyright Act. No
              rights are granted to you hereunder other than as expressly set
              forth herein.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Intellectual Property Rights</h2>
            <p>
              The Site and its entire contents, features and functionality
              (including but not limited to all information, software, text,
              displays, images, video and audio, and the design, selection and
              arrangement thereof), are owned by Zeta Analytics, its licensors
              or other providers of such material and are protected by United
              States and international copyright, trademark, patent, trade
              secret and other intellectual property or proprietary rights
              laws.
            </p>
            <p>
              This Agreement permits you to use the Site for your personal use
              only. You must not reproduce, distribute, modify, create
              derivative works of, publicly display, publicly perform,
              republish, download, store or transmit any of the material on
              our Site, except as follows:
            </p>
            <ul>
              <li>
                Your computer may temporarily store copies of such materials
                in RAM incidental to your accessing and viewing those
                materials.
              </li>
              <li>
                You may store files that are automatically cached by your web
                browser for display enhancement purposes.
              </li>
              <li>
                You may print or download one copy of a reasonable number of
                pages of the website for your own personal, non-commercial use
                and not for further reproduction, publication or distribution.
              </li>
              <li>
                If we provide desktop or other applications or databases for
                download, you may download a single copy to your computer or
                device solely for your own personal, non-commercial use,
                provided you agree to be bound by our end user license
                agreement for such applications.
              </li>
              <li>
                You must not:
                <ul>
                  <li>
                    Modify copies of any materials or data from the Site
                    unless expressly permitted by Zeta Analytics under our
                    separate end user license agreement.
                  </li>
                  <li>
                    Use any illustrations, photographs, video, animations or
                    any graphics separately from the accompanying text.
                  </li>
                  <li>
                    Delete or alter any copyright, trademark or other
                    proprietary rights notices from copies of materials from
                    the Site.
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              This permission does not include any resale or commercial use of
              this Site or its contents; any collection and use of any
              content; any derivative use of this Site or its contents; any
              downloading or copying of account information for the benefit of
              a third party; or any use of data mining, robots, or similar
              data gathering and extraction tools. This Site or any portion of
              this Site may not be reproduced, duplicated, copied, sold,
              resold, visited, or otherwise exploited for any commercial
              purpose without express written consent of Zeta Analytics. You
              may not remove, decompile, disassemble or reverse engineer any
              Site software or use any network monitoring or discovery
              software to determine the Site architecture. You may not frame
              or utilize framing techniques to enclose any trademark, logo, or
              other proprietary information (including images, text, page
              layout, or form) of Zeta Analytics without express written
              consent. You may not access the Site in order to (a) build a
              competitive data-related commercial product or service, or (b)
              copy any features, functions or graphics of our Services. You
              may not use any meta tags or any other "hidden text" utilizing
              our name or trademarks without our express written consent.
            </p>
            <p>
              If you print, copy, modify, download or otherwise use or provide
              any other person with access to any part of the Site in breach
              of this Agreement, your right to use the Site will cease
              immediately and you must, at our option, return or destroy any
              copies of the materials you have made. No right, title or
              interest in or to the Site or any content on the Site is
              transferred to you, and all rights not expressly granted are
              reserved by Zeta Analytics. Any use of the Site not expressly
              permitted by this Agreement is a breach of this Agreement and
              may violate copyright, trademark and other laws.
            </p>
            <p>
              The Zeta Analytics name, Zeta Analytics logo and other
              identifying marks we use are our service marks or registered
              service marks or trademarks. Other products and company names
              mentioned on the Site may be trademarks of their respective
              owners. Zeta Analytics's service marks, trademarks and trade
              dress may not be used in any manner that is likely to cause
              confusion among customers or in any manner that disparages or
              discredits Zeta Analytics or www.Zeta Analytics.com. All other
              trademarks not owned by Zeta Analytics or its subsidiaries that
              appear on the Site are the property of their respective owners,
              who may or may not be affiliated with, connected to, or
              sponsored by Zeta Analytics or its subsidiaries.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Prohibited Uses</h2>
            <p>
              You may not use the Site for the purpose of gathering
              information for or transmitting unsolicited commercial phone
              calls, facsimile transmissions, email or email that makes use of
              headers, invalid or nonexistent domain names, or other means of
              deceptive addressing. You may not use the Site in a manner that
              violates any local, state, national and international laws
              including, but not limited to, any law or regulation concerning
              email, telephone solicitations or facsimile transmissions or the
              export of data or software to and from the US or other
              countries. You may not use this Site to provide material support
              or resources (or to conceal or disguise the nature, location,
              source, or ownership of material support or resources) to any
              organization or organizations designated by the United States
              government as a foreign terrorist organization pursuant to
              section 219 of the Immigration and Nationality Act. You may not
              export or re-export the Site or any portion thereof, or any
              software available on or through the Site, in violation of the
              export control laws or regulations of the United States. Any
              unauthorized use terminates the permission or license granted by
              Zeta Analytics. You are granted a limited, nonexclusive,
              nontransferable, revocable right to create a hyperlink to the
              home page of the Site so long as the link does not portray the
              Site, Zeta Analytics, its affiliates, or their products or
              services in a false, misleading, derogatory, or otherwise
              offensive manner.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Linking to the Website</h2>
            <p>
              You may link to our homepage, provided you do so in a way that
              is fair and legal and does not suggest any form of association,
              approval or endorsement on our part without our express written
              consent.
            </p>
            <p>Subject to the foregoing, you must not:</p>
            <ul>
              <li>
                Establish a link from any website that is not owned by you.
              </li>
              <li>
                Cause the Site or portions of it to be displayed, or appear to
                be displayed by, for example, framing, deep linking or in-line
                linking, on any other site.
              </li>
              <li>
                Otherwise take any action with respect to the materials on
                this Website that is inconsistent with any other provision of
                these Terms of Use.
              </li>
            </ul>
            <p>
              The website from which you are linking, or on which you make
              certain content accessible, must comply in all respects with the
              Content Standards set out in these Terms of Use. You agree to
              cooperate with us in causing any unauthorized framing or linking
              immediately to cease. We reserve the right to withdraw linking
              permission without notice. We may disable all or any social
              media features and any links at any time without notice in our
              discretion.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Links from the Website</h2>
            <p>
              If the Website contains links to other sites and resources
              provided by third parties, these links are provided for your
              convenience only. This includes links contained in
              advertisements, including banner advertisements and sponsored
              links. We have no control over the contents of those sites or
              resources, and accept no responsibility for them or for any loss
              or damage that may arise from your use of them. If you decide to
              access any of the third party websites linked to this Site, you
              do so entirely at your own risk and subject to the terms and
              conditions of use for such websites.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">
              Reviews, Comments, Communications and Other Content
            </h2>
            <p>
              Visitors may submit suggestions, ideas, comments, questions, or
              other information ("Content"), so long as the Content is not
              illegal under any applicable state, federal or international
              laws and regulations, obscene, threatening, defamatory, invasive
              of privacy, infringing of intellectual property rights, in
              violation of any contractual or fiduciary relationships, likely
              to deceive any person, promoting illegal activity, or otherwise
              injurious to third parties or objectionable and does not consist
              of or contain software viruses, malicious computer code, files
              or software, political campaigning, commercial solicitation,
              chain letters, mass mailings, or any form of "spam." You may not
              use a false e-mail address, forge headers or manipulate
              identifiers, impersonate any person or entity, or otherwise
              mislead as to the origin of the Content. You agree not to
              interfere or disrupt our Services or servers or networks
              connected to our Services, or disobey any requirements,
              procedures, policies or regulations of networks connected to our
              Services, including using any device, software or routine to
              bypass our robot exclusion headers. Zeta Analytics reserves the
              right (but not the obligation) to remove or edit such Content.
              We assume no liability for any action or inaction regarding
              transmissions, communications or Content provided by any user or
              third party. We have no liability or responsibility to anyone
              for performance or nonperformance of monitoring the Content
              placed on the Site.
            </p>
            <p>
              If you do submit Content, and unless we indicate otherwise, you
              grant Zeta Analytics a nonexclusive, royalty-free, perpetual,
              irrevocable, and fully sublicensable license to use, reproduce,
              modify, adapt, publish, translate, perform, create derivative
              works from, distribute, and display such content in any media
              and otherwise disclose to third parties any such Content for any
              purpose. You grant Zeta Analytics the right to use the name that
              you submit in connection with such Content, if they choose. You
              represent and warrant that you own or otherwise control all of
              the rights to the Content that you post; that you have the right
              to grant the license granted herein to us; that the Content is
              accurate; that use of the Content you supply does not violate
              this policy and will not cause injury to any person or entity;
              that the Content you post does not constitute a "trade secret"
              under applicable law and has not been misappropriated from a
              third party; that such Content does and will continue to comply
              with this Agreement, as amended; and that you will indemnify
              Zeta Analytics or its affiliates for all claims resulting from
              Content you supply. You understand and acknowledge that you are
              responsible for any Content you submit or contribute, and you,
              not Zeta Analytics, have full responsibility for such content,
              including its legality, reliability, accuracy, and
              appropriateness.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Unlawful Activity</h2>
            <p>
              We reserve the right to investigate complaints or reported
              violations of the Site Policies and to take any action we deem
              appropriate, including but not limited to reporting any
              suspected unlawful activity to law enforcement officials,
              regulators, or other third parties and disclosing any
              information necessary or appropriate to such persons or entities
              relating to your profile, email addresses, usage history, posted
              materials, IP addresses and traffic information. YOU AGREE TO
              INDEMNIFY AND HOLD HARMLESS ZETA ANALYTICS FROM ANY CLAIMS
              RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES
              DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS
              TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES
              OR LAW ENFORCEMENT AUTHORITIES.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Removal of Account</h2>
            <p>
              You may request removal of your account, any associated email
              address and access to our Services by submitting a removal
              request to us.
            </p>
            <p>
              We may, without prior notice, immediately delete, limit your
              access to or suspend your account and access to our Services.
              Cause for such action shall include, but not be limited to, any
              breaches or violations of this Agreement or the Site Policies or
              other agreements or guidelines on the Site, requests by law
              enforcement or other government agencies, discontinuance or
              material modification to our Services (or any part thereof),
              unexpected technical or security issues or problems, extended
              periods of inactivity, your engagement in fraudulent or illegal
              activities, and/or nonpayment of any fees owed by you in
              connection with our Services. All deletions, limitations of
              access and account suspensions shall be made at our sole
              discretion and you agree that we shall not be liable to you or
              any third party for the deletion of your account or access to
              our Services.
            </p>
            <p>
              Our termination and removal of your account may include any or
              all of the following: (a) removal of access to all or part of
              the offerings within our Services, (b) deletion of your password
              and all related information (or any part thereof), and (c)
              barring of further use of all or part of our Services.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Disclaimer Of Warranties</h2>
            <p>
              THE SITE AND ALL CONTENT CONTAINED HEREIN IS PROVIDED BY ZETA
              ANALYTICS ON AN "AS IS" AND "AS AVAILABLE" BASIS. ZETA ANALYTICS
              MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR
              IMPLIED, AS TO THE OPERATION OF THIS SITE OR THE INFORMATION,
              CONTENT, MATERIALS, OR PRODUCTS INCLUDED ON THIS SITE. YOU
              EXPRESSLY AGREE THAT YOUR USE OF THIS SITE IS AT YOUR SOLE RISK.
              NEITHER ZETA ANALYTICS NOR ANY PERSON ASSOCIATED WITH ZETA
              ANALYTICS MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO
              THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR
              AVAILABILITY OF THE SITE. WITHOUT LIMITING THE FOREGOING,
              NEITHER ZETA ANALYTICS NOR ANYONE ASSOCIATED WITH ZETA ANALYTICS
              REPRESENTS OR WARRANTS THAT THE SITE, ITS CONTENT OR ANY
              SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL BE ACCURATE,
              RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE
              CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE
              ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SITE
              OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE WILL
              OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
            </p>
            <p>
              TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE LAW, ZETA ANALYTICS
              DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, STATUTORILY OR
              OTHERWISE, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
              MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR
              PURPOSE. ZETA ANALYTICS DOES NOT WARRANT THAT THIS SITE, ITS
              SERVERS, OR E-MAIL SENT FROM WWW. ZETA ANALYTICS.COM ARE FREE OF
              VIRUSES OR OTHER HARMFUL COMPONENTS. TO THE FULLEST EXTENT
              PERMITTED BY LAW, ZETA ANALYTICS WILL NOT BE LIABLE FOR ANY
              DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE,
              INCLUDING, BUT NOT LIMITED TO INDIRECT, SPECIAL, INCIDENTAL OR
              CONSEQUENTIAL DAMAGES (INCLUDING DAMAGES FOR LOSS OF BUSINESS,
              LOSS OF PROFITS, LITIGATION, OR THE LIKE), WHETHER BASED ON
              BREACH OF CONTRACT, BREACH OF WARRANTY, TORT (INCLUDING
              NEGLIGENCE), PRODUCT LIABILITY OR OTHERWISE, EVEN IF ADVISED OF
              THE POSSIBILITY OF SUCH DAMAGES. YOU ARE RESPONSIBLE FOR
              IMPLEMENTING SUFFICIENT PROCEDURES AND CHECKPOINTS TO SATISFY
              YOUR PARTICULAR REQUIREMENTS FOR ANTI-VIRUS PROTECTION. THE
              NEGATION AND LIMITATION OF DAMAGES SET FORTH ABOVE ARE
              FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN US AND
              YOU. THIS SITE AND THE PRODUCTS, SERVICES, AND INFORMATION
              PRESENTED WOULD NOT BE PROVIDED WITHOUT SUCH LIMITATIONS.
            </p>
            <p>
              WE SHALL NOT BE LIABLE FOR ANY LOSS, INJURY, CLAIM, LIABILITY,
              OR DAMAGE OF ANY KIND RESULTING IN ANY WAY FROM (A) ANY ERRORS
              IN OR OMISSIONS FROM THE SITE OR ANY SERVICES OR PRODUCTS
              OBTAINABLE FROM THE SITE, (B) THE UNAVAILABILITY OR INTERRUPTION
              OF THE SITE OR ANY FEATURES OF THE SITE, (C) YOUR USE OF THE
              SITE OR WITH THE DELAY OR INABILITY TO USE THE SITE, (D) THE
              CONTENT CONTAINED ON THE SITE, OR (E) ANY DELAY OR FAILURE IN
              PERFORMANCE BEYOND OUR CONTROL.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Limitation of Liability</h2>
            <p>
              TO THE FULLEST EXTENT PERMITTED BY LAW, THE AGGREGATE LIABILITY
              OF ZETA ANALYTICS, ITS SUBSIDIARIES AND ITS AFFILIATES IN
              CONNECTION WITH ANY CLAIM ARISING OUT OF OR RELATING TO THE
              SITE, OR THE PRODUCTS, SERVICES OR INFORMATION PROVIDED BY THE
              SITE SHALL NOT EXCEED $100 AND THAT AMOUNT SHALL BE IN LIEU OF
              ALL OTHER REMEDIES WHICH YOU MAY HAVE AGAINST US AND OUR
              AFFILIATES.
            </p>
            <p>
              ANY CAUSE OF ACTION YOU MAY HAVE WITH RESPECT TO THE SITE OR ANY
              PRODUCTS OR SERVICES PURCHASED USING THE SITE MUST BE BROUGHT BY
              YOU WITHIN ONE YEAR AFTER THE CAUSE OF ACTION ARISES OR BE
              FOREVER WAIVED AND BARRED.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Indemnification</h2>
            <p>
              You agree to indemnify, defend and hold us and our partners,
              agents, officers, directors, employees, subcontractors,
              successors and assigns, third party suppliers of information and
              documents, attorneys, advertisers and product and service
              providers harmless from any liability, loss, claim and expense,
              including reasonable attorney's fees, related to your violation
              of this Agreement, the Site Policies or use of the Site.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Applicable Law</h2>
            <p>
              By visiting www.zetanalytics.com, you agree that the laws of the
              state of Massachusetts, without regard to principles of conflict
              of laws, will govern this Agreement and the Site Policies and
              any dispute of any sort that might arise between you and Zeta
              Analytics or its affiliates.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Miscellaneous</h2>
            <p>
              The language in this Agreement shall be interpreted as to its
              fair meaning and not strictly for or against any party. The
              Terms of Use Agreement, including any Content or Published
              Information provided by you on the Site may be automatically
              assigned by us in our sole discretion to a third party in the
              event of an acquisition, sale, merger, or other change of
              control of Zeta Analytics. Should any part of the Terms of Use
              Agreement be held invalid or unenforceable, that portion shall
              be construed consistent with applicable law and the remaining
              portions shall remain in full force and effect. To the extent
              that anything in or associated with the Site is in conflict or
              inconsistent with this Agreement, the Terms of Use Agreement
              shall take precedence. Our failure to enforce any provision of
              the Terms of Use Agreement shall not be deemed a waiver of such
              provision nor of the right to enforce such provision. Our rights
              under the Terms of Use Agreement shall survive any termination
              of the Terms of Use Agreement.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">PRIVACY</h2>
            <p>
              This privacy policy ("Policy") explains how personal information
              is collected, used, and disclosed with respect to your use of
              our website located at www.zetanalytics.com (the "Site") and
              the. We are committed to protecting your privacy and providing
              you with a positive experience while using our Site and its
              applications and services. By using this Site, you agree to this
              Policy and consent to the privacy practices described in this
              Policy. If you do not agree with this Policy and our practices,
              do not use the Site.
            </p>
            <p>
              We reserve the right to change the provisions of this Policy at
              any time. We will indicate at the end of the privacy policy the
              date it was last updated. Your use of the Site following the
              posting of such changes or revised statement shall constitute
              your consent to any changes in our privacy practices, and you
              expressly waive any right to receiving notice of such changes.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Scope</h2>
            <p>
              This Policy explains your rights and obligations in relation to
              your personal information. It applies to information we collect
              through your use of the Zeta Analytics Site and in email and
              other electronic messages between you and Zeta Analytics or the
              Site.
            </p>
            <p>
              This Policy does not apply to information obtained independently
              by Zeta Analytics offline or from another source.
            </p>
            <p>
              Privacy rights and obligations may differ in certain locations
              based on local law, in which case Zeta Analytics will comply
              with the local legal requirements.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">
              Collection of Personal Information
            </h2>
            <p>
              "Personal information" includes personal identifiers or
              information about you such as your name, physical address, email
              address, phone number, login information, marketing preferences,
              company affiliation, geographic location or payment card
              information. You may be required to provide information about
              yourself when you register for trial or subscription access to
              Site applications and services, sign up for news and analysis
              alerts or submit content material or comments.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">
              Collection of Site Use Information
            </h2>
            <p>
              We may use automatic data collection technologies to collect
              certain information about your equipment, browsing actions, and
              patterns, including details of your visits to our Site
              (including traffic data, logs, and other communication data and
              the resources that you access and use on the Site) and
              information about your computer and Internet connection,
              including your IP address, operating system, and browser type.
            </p>
            <p>
              Similar to other commercial websites, the Site may use a
              standard technology called "cookies" and web server logs to
              collect information about how the Site is used. A cookie is a
              very small text document, which often includes an anonymous
              unique identifier. When visiting the Site, our servers may query
              your computer to see if there are cookies previously set by Zeta
              Analytics. Afterwards, every time you visit the Site, your
              browser retrieves and sends this file to the website's server.
              You can configure your browser to notify you whenever a cookie
              is being sent, or to refuse all cookies. Please note that some
              features for the Site and its applications may not function
              properly if cookies are disabled for your browser. Unless you
              have adjusted your browser settings so that it will refuse
              cookies, our system will issue cookies when you direct your
              browser to our Site.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Communication Information</h2>
            <p>
              If you choose to correspond with us through email, or any other
              method of communication we may retain the content of your email
              messages or communications together with your email address or
              other contact information and our responses. If you choose to
              sign up for an email news alert, we will send the alert to the
              email address you register with us.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">
              Sharing Information with Third Parties
            </h2>
            <p>
              We may disclose personal information that we collect or you
              provide as described in this Policy:
            </p>
            <ul>
              <li>
                To contractors, service providers, and other third parties we
                may use to support our business and develop further the Site
                and its applications and services.
              </li>
              <li>
                To a buyer or other successor in the event of a merger,
                divestiture, restructuring, reorganization, dissolution, or
                other sale or transfer of some of all of Zeta Analytics's
                assets, in which personal information held by Zeta Analytics
                about our Site users is among the assets transferred.
              </li>
              <li>
                To fulfill the purpose for which you provide it. For example,
                if you give us your email address and the email address of a
                colleague to subscribe the colleague to the Site's features,
                we may transmit your email address to the colleague.
              </li>
              <li>
                For any other purpose disclosed by us when you provide the
                information.
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">
              Security of Personal Information
            </h2>
            <p>
              Zeta Analytics has physical, administrative and technical
              safeguards in place to help protect the personal information it
              collects. While there is no such thing as perfect security on
              the Internet, we will take reasonable and appropriate steps to
              ensure the safety of your personal information. Unfortunately,
              the transmission of information via the Internet is not
              completely secure. Although we do our best to protect your
              personal information, we cannot guarantee the security of your
              personal information transmitted to our Site. Any transmission
              of personal information is at your own risk. We are not
              responsible for circumvention of any privacy settings or
              security measures contained on the Site.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">
              Retention of Personal Information
            </h2>
            <p>
              Zeta Analytics will retain your personal information as needed
              to fulfill the purposes for which it was collected. We will
              retain and use your personal information as necessary to comply
              with our business requirements, legal obligations, resolve
              disputes, protect our assets and enforce our agreements.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Information Correction</h2>
            <p>
              We will correct data that it is inaccurate or delete it at your
              request if it is not otherwise required to be retained by law or
              for legitimate business purposes. Because of the way we maintain
              certain services, after you delete your information, residual
              copies may take a period of time before they are deleted from
              our active servers and may remain in our backup systems.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Certain Disclosures</h2>
            <p>
              We may disclose your personal information if required to do so
              by law or subpoena or if we believe that such action is
              necessary to (a) conform to the law or comply with any court
              order or legal process served on us, including any response
              necessary to comply with any government or regulatory request or
              investigation; (b) protect and defend our rights and property,
              the Site, the users of the Site and/or our affiliated parties;
              (c) act under circumstances to protect the safety of users of
              the Site, us, or third parties; or (d) or if we have a good
              faith belief that disclosure is reasonably necessary to (i)
              investigate, prevent, or take action regarding suspected or
              actual illegal activities or to assist government enforcement
              agencies; (ii) enforce the Site's Terms and Conditions,
              investigate and defend ourselves against any third-party claims
              or allegations, or protect the security or integrity of our
              Service; or (iii) exercise or protect the rights, property, or
              safety of Zeta Analytics, our users, personnel, or others.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">
              Cross-Border Transfer of Data
            </h2>
            <p>
              We process personal information on servers located in the United
              States of America and in other countries. In some cases, we may
              process personal information outside your own country. If you
              use the Site from outside the United States and provide personal
              information to us, your personal information may be transferred
              to the United States. If you use the Zeta Analytics Service from
              within the United States and provide personal information to us,
              your personal information may be transferred out of the United
              States. Through your use of our Service, you consent to such
              transfer and to the use of the information between countries.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h2 className="za-about-subject">Consent</h2>
            <p>
              By using our Site you consent to our collection and use of your
              personal information as described in this Privacy Policy. It is
              our policy to post any changes we make to our privacy policy on
              this page. If we make material changes to how we treat our
              users' personal information, we will notify you by email to the
              email address specified in your account or through a notice on
              the Site home page.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <h5 style={{ fontSize: '14px' }}>
              <b>Updated</b> - February 1, 2018
            </h5>
            <h5 style={{ fontSize: '14px' }}>
              <b>COPYRIGHT</b> 2018 Zeta Analytics, Inc. All Rights Reserved.
            </h5>
          </div>
        </div>
      </div>
    </div>;
  }
};
