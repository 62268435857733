import * as React from "react";

const form_fields = [
  { label: 'First name', name: 'first_name', value: '', type: 'text' },
  { label: 'Last name', name: 'last_name', value: '', type: 'text' },
  { label: 'Email', name: 'email', value: '', type: 'email' },
  { label: 'Company / Organization', name: 'organization', value: '', type: 'text' },
  { label: 'Access the free trial version (MGV: Producers-Platforms)', name: 'mgv_producers_platforms', value: true, type: 'checkbox' },
  { label: 'Request subscription info for full version', name: 'sightline_demo', value: true, type: 'checkbox' },
  { label: 'Sign up for Fast Tracks', name: 'fast_tracks', value: true, type: 'checkbox' }
];

export class ContactComponent extends React.Component {

  constructor(props) {
    super(props);
    const formState = { question: '' };
    form_fields.forEach(d => {
      formState[d.name] = d.value;
    });

    this.state = formState;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.questionSubmit = this.questionSubmit.bind(this);

  }

  handleChange(event) {
    const temp = {};
    temp[event.target.name] = event.target['value'];
    this.setState(temp);
  }

  handleSubmit(event) {
    console.log('form submitted: ', this.state);
    event.preventDefault();
  }

  questionSubmit() {
    console.log(this.state);
  }

  render() {
    const phone = '+1-508-214-5997';
    const email = 'info@zetanalytics.com';

    const contactForm = form_fields.map(field => {
      if (field.type === 'checkbox') {
        return <div key={field.name} className="form-group">
          <input type={field.type}
            name={field.name}
            onChange={this.handleChange}
            value={this.state[field.name]}
            style={{ marginRight: '10px' }} />
          <label>{field.label}</label>
        </div>;
      } else {
        return <div key={field.name} className="form-group">
          <label>{field.label}</label>
          <input type={field.type}
            name={field.name}
            onChange={this.handleChange}
            value={this.state[field.name]}
            placeholder={'Enter ' + field.label}
            className="form-control" />
        </div>;
      }

    });

    return (
      <section className="contact-component">

        <div className="za-body contactpage actionate">
          <div className="row justify-content-center">

            <div className="col-5">

              <div className="row">
                <div className="col-12">
                  <h3>Contact us</h3>
                  <p><a href={'mailto:' + email}>{email}</a></p>
                  <p><a href={'tel:' + phone}>{phone}</a></p>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <p>Questions, feedback, suggestions? We want to hear from you:</p>
                  <textarea name="question"
                    value={this.state['question']}
                    onChange={this.handleChange} />
                  <button style={{ marginTop: '10px' }}
                    onClick={this.questionSubmit}
                    className="btn btn-secondary">Submit</button>
                </div>
              </div>

            </div>
            <div className="col-7">

              <div className="row">
                <div className="col-12">
                  <h3>ACCESS</h3>
                  <h3>Interested in Sightline?</h3>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="card-body">

                      <form onSubmit={this.handleSubmit}>
                        {contactForm}
                        <button type="submit" className="btn btn-light">Submit</button>
                      </form>

                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>

      </section>
    );
  }
}