
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const FilterModal = (props, ref) => {
  const [modal, setModal] = useState(false);
  const className = 'filter-modal';
  const backdrop = true;

  const toggle = () => setModal(!modal);

  useImperativeHandle(ref, () => ({
    toggle
  }));

  return (
    <Modal isOpen={modal} toggle={toggle} className={className} backdrop={backdrop} size='xl'>
      <ModalHeader toggle={toggle}>{props.title}</ModalHeader>
      <ModalBody style={{ height: '600px', fontSize: '12px', overflow: 'scroll' }}>
        {props.children}
      </ModalBody>
      {/* <ModalFooter>
        <Button color="secondary" onClick={toggle}>Cancel</Button>
      </ModalFooter> */}
    </Modal>
  );
};

export default forwardRef(FilterModal);