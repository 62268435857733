import * as d3 from 'd3';
import * as L from 'leaflet';
import * as _ from 'lodash';
import {
  COLORS, RADIUS, RADIUS_GROUP, COLOR_GROUP, COLORS_PLATFORM,
  COLORS_COMPANY, COLORS_SYSTEM, linkMouseOver, linkMouseOut,
  filterClicked, filterConcentricCircle
} from './utils';

export default class NetworkChartClass {
  simulation;
  colorScale;
  links;
  nodes;
  link;
  node;
  mainG;

  constructor() {
    this.addArrowMarker = this.addArrowMarker.bind(this);
    this.initChart = this.initChart.bind(this);
    this.stopSimulation = this.stopSimulation.bind(this);
    this.updateChart = this.updateChart.bind(this);
    this.drag = this.drag.bind(this);
    this.ticked = this.ticked.bind(this);
    this.chartTick = this.chartTick.bind(this);
    this.mapTick = this.mapTick.bind(this);
    this.isolateNodesEdgesClick = this.isolateNodesEdgesClick.bind(this);
    this.isolateNodesEdgesHover = this.isolateNodesEdgesHover.bind(this);
  }

  // path mid arrow marker
  addArrowMarker = (svg) => {
    svg.append('defs')
      .append('marker')
      .attr('id', 'arrow')
      .attr('refX', 5)
      .attr('refY', 5)
      .attr('viewBox', '0 0 10 10')
      .attr('markerWidth', 4)
      .attr('markerHeight', 4)
      .attr('orient', 'auto')
      .append('path')
      .style('fill', '#636363')
      .attr('d', 'M 0 0 L 10 5 L 0 10 z');
  }

  // initialise the chart
  initChart = ({
    svg, width, height
  }) => {
    console.log('initialising chart')

    //  select the svg
    // svg = d3.select(container).select('svg');

    // add the arrow marker
    this.addArrowMarker(svg);

    // add node color scale
    this.colorScale = (d) => {
      if (d.type_code) {
        return COLORS[+d.type_code] || `rgb(${d.type_code})`;
      } else if (d.labels[0].toLowerCase() === 'platform') {
        return COLORS_PLATFORM;
      } else if (d.labels[0].toLowerCase() === 'system') {
        return COLORS_SYSTEM;
      } else if (d.labels[0].toLowerCase() === 'company') {
        return COLORS_COMPANY;
      } else {
        return COLOR_GROUP;
      }
    }; // d3.scaleOrdinal(d3.schemeCategory10)(d.country);

    // initialise the force simulation
    this.simulation = d3.forceSimulation()
      .force("collide", d3.forceCollide().radius(3))
      .force("charge", d3.forceManyBody().strength(-50))
      .force("center", d3.forceCenter(width / 2, height / 2));

    // initialise the nodes and links containers
    this.mainG = svg.append('g').attr('class', 'main-container');
    this.links = this.mainG.append('g').attr('class', 'links');
    this.nodes = this.mainG.append('g').attr('class', 'nodes');

    svg.call(
      d3.zoom()
        .scaleExtent([0.5, 3])
        .on('zoom', () => {
          this.zoomed(this.mainG);
        })
    );
  }

  stopSimulation = () => {
    this.simulation.stop();
  }

  // update the chart on data change
  updateChart = ({
    data,
    width,
    height,
    dispatch,
    selectNode,
    hoverNode,
    selectEdge,
    clearSelection,
    tooltipRef,
    positionType,
    map
  }) => {
    const self = this;
    console.log('updating chart')
    // console.log(data);

    // nodes and links data
    const linksData = data.links; //.map(d => d); // Object.create(d));
    const nodesData = data.nodes;
    // const nodesData = data.nodes.map(node => {
    //   if (map) {
    //     const latlng = new L.LatLng(node.lat, node.long);
    //     node.mapx = map.latLngToLayerPoint(latlng).x;
    //     node.mapy = map.latLngToLayerPoint(latlng).y;
    //   }
    //   return node;
    // }); // .map(d => d); //Object.create(d));

    this.simulation
      .nodes(nodesData)
      .force("link", d3.forceLink(linksData).id(d => d.nodeId).distance(100))
      .force("center", d3.forceCenter(width / 2, height / 2));

    // create the links
    this.link = this.links
      .selectAll("path")
      .data(linksData)
      .join("path")
      .attr('marker-mid', d => {
        if (d.properties.directionType && d.properties.directionType === 'Directed') {
          return 'url(#arrow)'
        } else if (d.properties.directionType && d.properties.directionType === 'Undirected') {
          return '';
        } else {
          return 'url(#arrow)'
        }
      })
      .attr("stroke", d => {
        if(d.properties.color) {
          return `rgb(${d.properties.color})`;
        } else if(d.source.labels[0] === 'SYSTEM' && d.target.labels[0] === 'PLATFORM') {
          return '#66c2a5';
        } else if(d.source.labels[0] === 'COMPANY' && d.target.labels[0] === 'SYSTEM') {
          return '#fc8d62';
        } else if(d.source.labels[0] === 'SYSTEM' && d.target.labels[0] === 'SYSTEM') {
          return '#8da0cb';
        } else if(d.source.labels[0] === 'COMPANY' && d.target.labels[0] === 'COMPANY') {
          return '#bc80bd';
        } else {
          return 'rgb(255, 255, 255)';
        }
      })
      .attr("stroke-opacity", 1)
      .attr("stroke-dasharray", d => {
        if (d.properties.style) {
          const style = d.properties.style;
          return +style === 1 ? "none" :
            +style == 2 ? "4 2" : "2 2"
        } else if(d.properties.operationalStatus || d.properties.status) {
          if(
            (
              d.source.labels[0] === 'SYSTEM' &&
              d.target.labels[0] === 'PLATFORM' &&
              d.properties.operationalStatus === 'Phaseout'
            ) ||
            (
              d.source.labels[0] === 'COMPANY' &&
              d.target.labels[0] === 'SYSTEM' &&
              d.properties.status === 'Competitor'
            )
          ) {
            return "10,5";
          } else {
            return 'none';
          }
        } else {
          return "none";
        }
      })
      .attr("stroke-width", d => +d.properties.width * 1.2 || 1.2);

    this.link.on('click', d => {
      // dispatch(hoverNode(false));
      dispatch(selectEdge(d.properties));
    });

    this.link.on('mouseover', d => {
      linkMouseOver(d, tooltipRef, d3.event);
    });

    this.link.on('mouseout', d => {
      linkMouseOut(tooltipRef);
    });

    // create the nodes
    this.node = this.nodes
      .selectAll(".node")
      .data(nodesData, d => d.id + d.index)
      .join('g')
      .attr('class', 'node');

    this.node.append('circle')
      .attr("r", d => d.nodeType === 'group' ? RADIUS_GROUP : RADIUS)
      .style("fill", this.colorScale)
      .style("stroke", "#fff")
      .style("stroke-width", 1.5);

    // inner concentric circle
    // this.node
    //   .filter(d => {
    //     return filterConcentricCircle(d, 'Boeing', 'prime_integrator', 'mae')
    //   })
    //   .append('circle')
    //   .classed('inner-circle', true)
    //   .attr("r", d => d.nodeType === 'group' ? RADIUS_GROUP + 3 : RADIUS + 3)
    //   .style("stroke", '#1f78b4')
    //   .style("fill", "transparent")
    //   .style("stroke-width", 1.5);

    // outer concentric circle
    this.node
      .filter(d => {
        // return filterConcentricCircle(d, 'US', 'country', 'mae') ||
        return (d.threat_level && ['High', 'Very High'].indexOf(d.threat_level) !== -1)
      })
      .append('circle')
      .attr("r", d => d.nodeType === 'group' ? RADIUS_GROUP + 6 : RADIUS + 6)
      .style("stroke", '#e31a1c')
      .style("fill", "transparent")
      .style("stroke-width", 1.5);

    this.node.append('text')
      .attr('y', -15)
      .style('text-anchor', 'end')
      .style('fill', '#fff')
      .style('font-size', '10px')
      .text(d => d.id);

    this.node
      .call(this.drag(this.simulation, map, positionType));

    this.node.on('click', function (d) {
      // self.node.classed('node-clicked', false);
      // d3.select(this).classed('node-clicked', true);
      // dispatch(hoverNode(false));
      dispatch(selectNode(d));
      self.isolateNodesEdgesClick(nodesData, linksData, d, this);
    });

    this.node.on('mouseover', function (d) {
      dispatch(hoverNode(d));
      // dispatch(selectNode(d));
      self.isolateNodesEdgesHover(nodesData, linksData, d, this);
    });

    this.node.on('mouseout', function (d) {
      self.link.classed('highlight-link', false);
      self.link.classed('isolated-hover-show', false);
      self.node.classed('isolated-hover-show', false);
      dispatch(hoverNode(false));

      // const clicked = d3.select(this).classed('node-clicked');
      // if (!clicked) {
      //   dispatch(clearSelection());
      // }
    });

    // update simulation and restart
    this.simulation
      .on('tick', () => { this.ticked(positionType, map); })
      .alpha(1).restart();

    // if (map) {
    //   map.on('moveend', () => {
    //     this.node.each((k) => {
    //       const latlng = new L.LatLng(k.lat, k.long);
    //       k.mapx = map.latLngToLayerPoint(latlng).x;
    //       k.mapy = map.latLngToLayerPoint(latlng).y;
    //     });
    //     this.simulation.alpha(1).restart();
    //     // ticked(positionType, map);
    //   });
    // }
  };

  // on each simulation tick ticked is called
  ticked = (positionType, map) => {
    if (this.link && this.node) {
      if (positionType === 'chart') {
        this.chartTick(this.link, this.node);
      } else {
        this.mapTick(map, this.link, this.node);
      }
    }
  }

  chartTick = (link, node) => {
    // new link created on tick
    link.attr('d', d => {
      const s = d.source;
      const t = d.target;

      // add a mid point to the path for arrow mid marker
      const midX = (t.x + s.x) / 2;
      const midY = (t.y + s.y) / 2;

      // create the new path
      return `M ${s.x} ${s.y} L ${midX} ${midY} L ${t.x} ${t.y}`;
    });

    // new node location on tick
    node.attr('transform', d => `translate(${d.x}, ${d.y})`);
  }

  mapTick = (map, link, node) => {
    node.attr('transform', d => {
      return `translate(${d.mapx}, ${d.mapy})`;
    });

    link.attr('d', d => {
      const s = d.source;
      const t = d.target;

      // add a mid point to the path for arrow mid marker
      const midX = (t.mapx + s.mapx) / 2;
      const midY = (t.mapy + s.mapy) / 2;

      // create the new path
      return `M ${s.mapx} ${s.mapy} L ${midX} ${midY} L ${t.mapx} ${t.mapy}`;
    });
  };

  zoomed(g) {
    g.attr('transform', d3.event.transform);
  }

  // add dragging to the nodes
  drag(simulation, map, positionType) {

    const dragstarted = (d) => {
      if (!d3.event.active) simulation.alphaTarget(0.3).restart();
      if (positionType === 'map') {
        d.fx = d.mapx;
        d.fy = d.mapy;
      } else {
        d.fx = d.x;
        d.fy = d.y;
      }
    };

    const dragged = (d) => {
      if (positionType === 'map') {
        d.mapx = d3.event.x;
        d.mapy = d3.event.y;
      } else {
        d.fx = d3.event.x;
        d.fy = d3.event.y;
      }
    };

    const dragended = (d) => {
      if (!d3.event.active) simulation.alphaTarget(0);
      if (positionType === 'map') {
        const latlng = new L.LatLng(d.lat, d.long);
        d.mapx = map.latLngToLayerPoint(latlng).x;
        d.mapy = map.latLngToLayerPoint(latlng).y;
      } else {
        d.fx = null;
        d.fy = null;
      }
    };

    return d3.drag()
      .on("start", dragstarted)
      .on("drag", dragged)
      .on("end", dragended);
  }

  // isolate on click
  isolateNodesEdgesClick(nodesData, linksData, d, self) {
    const isClickedNode = d3.select(self).classed('node-clicked');

    if (!isClickedNode) {
      const { nodes, links } = filterClicked(linksData, nodesData, d);

      this.link.classed('hidden', true);
      this.node.classed('hidden', true);
      this.node.classed('node-clicked', false);
      d3.select(self).classed('node-clicked', true);

      this.link
        .classed('isolated-link', d => {
          const isolated = links.find(k => k.properties.id === d.properties.id);
          return isolated ? true : false;
        });

      this.node
        .classed('isolated-node', d => {
          const isolated = nodes.find(k => k.nodeId === d.nodeId);
          return isolated ? true : false;
        });

    } else {
      this.link.classed('hidden', false);
      this.node.classed('hidden', false);
      this.link.classed('isolated-link', false);
      this.node.classed('isolated-node', false);
      this.node.classed('node-clicked', false);
      this.link.classed('highlight-link', false);
    }
  }

  // isolate on hover
  isolateNodesEdgesHover(nodesData, linksData, d, self) {
    const { nodes, links } = filterClicked(linksData, nodesData, d);
    const isolated = d3.select(self).classed('isolated-node');

    // if already in isolated view then show links with low opacity
    if (isolated) {
      this.link
        .filter(function (d) {
          return !d3.select(this).classed('isolated-link');
        })
        .classed('isolated-hover-show', d => {
          const isolated = links.find(k => k.properties.id === d.properties.id);
          return isolated ? true : false;
        });

      this.node
        .filter(function (d) {
          return !d3.select(this).classed('isolated-node');
        })
        .classed('isolated-hover-show', d => {
          const isolated = nodes.find(k => k.nodeId === d.nodeId);
          return isolated ? true : false;
        });

      // else highlight the link
    } else {
      this.link
        .classed('highlight-link', d => {
          const hightlighted = links.find(k => k.properties.id === d.properties.id);
          return hightlighted ? true : false;
        });
    }
  }
}

