import * as React from "react";

export class AdminComponent extends React.Component {
  render() {

    const contacts = [];
    const users = [];

    const setYesOrNo = d => {
      return d ? 'Yes' : 'No';
    };

    const contactFormTableView = contacts.map((row, index) => {
      return <tr key={index}>
        <td>{row.firstname}</td>
        <td>{row.lastname}</td>
        <td>{row.email}</td>
        <td>{row.organization}</td>
        <td>{setYesOrNo(row.sightline_demo)}</td>
        <td>{setYesOrNo(row.fast_tracks)}</td>
        <td>{row.question}</td>
      </tr>
    });

    const contactFormTableStyle = {
      minHeight: '200px',
      maxHeight: '400px',
      overflow: 'scroll'
    };

    const existingUsersTableBodies = users.map((user, index) => {
      return <tbody key={index}>
        <tr ng-click="toggleVisible()" className="user-info">
          <td>{user.username}</td>
          <td>{user.role}</td>
          <td />
        </tr>
        <tr ng-show="simpleCollapse.visible" className="warning">
          <td>
            {/* <select ng-init="modifiedUser.role = user.role" ng-model="modifiedUser.role" ng-options="role for role in roleOptions" className="form-control"></select> */}
          </td>
          <td>
            <button type="button" ng-disabled="user.role == modifiedUser.role" ng-click="updateUserRole(user, modifiedUser)" className="btn btn-sm btn-danger">Update Role</button>
          </td>
          <td>
            <button type="button" ng-hide="user.username == &quot;root&quot;" ng-click="deleteUser(user)" className="btn btn-sm btn-danger">Delete User</button>
          </td>
        </tr>
        <tr ng-show="simpleCollapse.visible" className="warning">
          <td>
            <input type="password" placeholder="password" ng-model="modifiedUser.password" className="form-control" />
          </td>
          <td>
            <input type="password" placeholder="again" ng-model="modifiedUser.passwordAgain" className="form-control" />
          </td>
          <td>
            <button type="button" ng-disabled="!validatePassword(modifiedUser)" ng-click="updateUserPassword(user, modifiedUser)" className="btn btn-sm btn-danger">Reset Password</button>
          </td>
        </tr>
      </tbody>
    });

    return (
      <section className="admin-component">

        <div className="admin-interface below-nav">
          <div>
            <h4>Edit Existing Users</h4>
            <table className="table">
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Role</th>
                </tr>
              </thead>
              {existingUsersTableBodies}
            </table>
          </div>
          <div className="well">
            <h4>Register New User</h4>
            <form>
              <div className="form-group">
                <label>Email</label>
                <input type="email" placeholder="bob@bob.com" ng-model="newUser.username" className="form-control" />
              </div>
              <div className="form-group">
                <label>Password</label>
                <input type="password" placeholder="Password" ng-model="newUser.password" className="form-control" />
              </div>
              <div className="form-group">
                <label>Password Again</label>
                <input type="password" placeholder="again" ng-model="newUser.passwordAgain" className="form-control" />
              </div>
              <button type="submit" ng-click="registerUser(newUser)" ng-disabled="!validateNewUser(newUser)" className="btn btn-primary">Add User</button>
            </form>
          </div>
          <h4>Contacts from Contact form</h4>
          <table style={contactFormTableStyle} className="table table-striped">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Organization</th>
                <th>SightLine Demo</th>
                <th>Fast Tracks Signup</th>
                <th>Question</th>
              </tr>
            </thead>
            <tbody>
              {contactFormTableView}
            </tbody>
          </table>
        </div>

      </section>
    );
  }
}