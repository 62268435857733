import React, { useState } from "react";
import { loginUser, fetchDataStart, fetchDataSuccess, fetchDataError } from "../../redux/reducers/chartData/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const updateUsername = (e) => {
    setUsername(e.target.value)
  };

  const updatePassword = (e) => {
    setPassword(e.target.value)
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    dispatch(fetchDataStart());
    try {
      await loginUser(username, password)
      dispatch(fetchDataSuccess());
      history.push('/');
    } catch (error) {
      dispatch(fetchDataError(error));
    }

    console.log(username, password)
  };

  return (
    <div className="card" style={{ width: '50%', marginLeft: '25%', marginTop: '5%' }}>
      <div className="card-body">
        <form>
          <div className="form-group">
            <label>User Name</label>
            <input type="username" className="form-control" value={username} aria-describedby="username" onChange={updateUsername} />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input type="password" className="form-control" value={password} onChange={updatePassword} />
          </div>

          <button type="submit" className="btn btn-primary" onClick={onSubmit}>Submit</button>
        </form>
      </div></div>
  );
};

export default Login;