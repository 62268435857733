export const API_ROOT = 'http://localhost:3714/api';

export const DEFAULT_QUERY = {
    edges: [
        "MATCH (a1)-[r {LABEL: 'Platform'}]->(a2)",
        "RETURN a1 AS source, a2 AS target, r as properties, type(r) as edge_label"
    ]
};

export const DEFAULT_MAE_QUERY = {
    edges: [
        "MATCH (a1)-[r]->(a2)",
        "RETURN a1 AS source, a2 AS target, r as properties, type(r) as edge_label"
    ]
};

export const MGV_FILTER_GROUPS = {
    platform_relationships: {
        label: 'PLATFORM RELATIONSHIPS',
        pos: 'left',
        children: [
            [
                { label: "Role" },
                { label: "Role Specific" }
            ]
        ]
    },
    platform: {
        label: 'PLATFORMS',
        pos: 'left',
        children: [
            [
                { label: "Vehicle Family" },
                { label: "Status" },
                { label: "Drive" }
            ],
            [
                { label: "Prime" },
                { label: "Customer" },
                { label: "Competition" }
            ],
            [
                { label: "Vehicle Class" },
                { label: "Vehicle Specific" }
            ]
        ]
    },
    alliance_relationships: {
        label: 'ALLIANCE RELATIONSHIPS',
        pos: 'right',
        children: [
            [
                { label: "Relationship" },
                { label: "Category" }
            ]
        ]
    },
    companies: {
        label: 'COMPANIES',
        pos: 'right',
        children: [
            [
                { label: "Core Veh. Activity" },
                { label: "Country" },
                { label: "Region" }
            ],
            [
                { label: "Corporate Group" },
                { label: "Company" },
                { label: "Company Type" }
            ]
        ]
    }
};

export const MGV_FILTERS = [
    { label: "Role", key: "ROLE", type: "Platform Edge", multi: true, range: 3 },
    { label: "Role Specific", key: "ROLE SPECIFIC", type: "Platform Edge", multi: true, range: 5 },
    { label: "Relationship", key: "RELATIONSHIP", type: "Alliance Edge", multi: true, range: 2 },
    { label: "Category", key: "ALLIANCE_TYPE", query: "MATCH (n)-[r {LABEL: 'Alliance'}]-(q) return DISTINCT r['ALLIANCE_TYPE'] as ALLIANCE_TYPE", type: "Alliance Edge" },
    { label: "Vehicle Family", key: "VEHICLE_FAMILY", query: "MATCH (n:PLATFORM) return distinct n['VEHICLE_FAMILY'] as VEHICLE_FAMILY;", type: "Platform Node", multi: true, range: 2 },
    { label: "Drive", key: "CONFIGURATION", type: "Platform Node", multi: true, range: 2 },
    { label: "Customer", key: "Customer", type: "Platform Node", multi: true, range: 11 },
    { label: "Status", key: "PROGRAM_STATUS", type: "Platform Node", multi: true, range: 2 },
    { label: "Prime", key: "PRIME", query: "MATCH (n:PLATFORM) return distinct n['PRIME'] as PRIME;", type: "Platform Node", multi: true, range: 3 },
    { label: "Core Veh. Activity", key: "VEHICLE SEGMENT", type: "Company Node", multi: true, range: 5 },
    { label: "Corporate Group", key: "CORP_GROUP", type: "Company Node", multi: true, range: 2 },
    { label: "Company", key: "id", type: "Company Node", multi: true, range: 2 },
    { label: "Country", key: "COUNTRY", query: "MATCH (n:COMPANY) return distinct n['COUNTRY'] as COUNTRY;", type: "Company Node" },
    { label: "Region", key: "REGION", query: "MATCH (n:COMPANY) return distinct n['REGION'] as REGION;", type: "Company Node" },
    { label: "Vehicle Role", key: "VEHICLE_ROLE", type: "Company Node", multi: true, range: 2 },
    { label: "Company Type", key: "COMPANY_TYPE", query: "MATCH (n:COMPANY) return distinct n['COMPANY_TYPE'] as COMPANY_TYPE;", type: "Company Node" },
    { label: "Vehicle Class", key: "Class", type: "Platform Node", multi: true, range: 2 },
    { label: "Competition", key: "COMPETITION", query: "MATCH (n:PLATFORM) return distinct n['COMPETITION'] as COMPETITION;", type: "Platform Node" },
    { label: "Edge Label", key: "platform_edge_types", query: "MATCH (n)-[r {LABEL: 'Platform'}]-(q) return distinct type(r) as platform_edge_types;", type: "Platform Edge Type" },
    { label: "Edge Label", key: "alliance_edge_types", query: "MATCH (n)-[r {LABEL: 'Alliance'}]-(q) return distinct type(r) as alliance_edge_types;", type: "Alliance Edge Type" },
    { label: "Vehicle Specific", key: "id", query: "MATCH (n:PLATFORM) return distinct n['id'] as id", type: "Platform Node" }
];

export const MGV_MAIN_FILTERS = {
    nodes: [
        { name: "All", a1: "", a2: "" },
        { name: "Company-Platform", a1: ":COMPANY", a2: ":PLATFORM" },
        { name: "Company-Company", a1: ":COMPANY", a2: ":COMPANY" },
        { name: "Platform-Platform", a1: ":PLATFORM", a2: ":PLATFORM" }
    ],
    links: [
        { name: "All", r: "" },
        { name: "Alliance", r: "Alliance" },
        { name: "Platform", r: "Platform" },
        { name: "Platform (OEM Radial)", r: "Platform (OEM Radial)" }]
};

export const MAE_FILTER_GROUPS = {
    // systems: {
    //     label: 'SYSTEMS',
    //     pos: 'left',
    //     children: [
    //         { label: "System Specific" },
    //         { label: "System Family" },
    //         { label: "System Type" },
    //         { label: "Program Status" },
    //         { label: "Prime/OEM" },
    //         { label: "Customer" }
    //     ]
    // },
    // platforms: {
    //     label: 'PLATFORMS',
    //     pos: 'left',
    //     children: [
    //         { label: "Platform Specific" },
    //         { label: "Platform Family" },
    //         { label: "Platform Type" },
    //         { label: "Platform Model/Version" },
    //         { label: "Prime/Integrator" },
    //         { label: "Customer" }
    //     ]
    // },
    companies: {
        label: 'COMPANIES',
        pos: 'left',
        children: [
            { label: "Company" },
            { label: "Corporate Group" },
            { label: "Market Segment" },
            { label: "Country" },
            { label: "Prime/Integrator" },
            { label: "Region" }
        ]
    },
    // system_platform_rels: {
    //     label: 'SYSTEM-PLATFORM RELATIONSHIPS',
    //     pos: 'right',
    //     children: [
    //         { label: "Mission" },
    //         { label: "Operational Status" }
    //     ]
    // },
    // company_system_rels: {
    //     label: 'COMPANY-SYSTEM RELATIONSHIPS',
    //     pos: 'right',
    //     children: [
    //         { label: "Role" }
    //     ]
    // },
};

export const MAE_FILTERS = [
    { label: "System Specific", key: "id", query: "MATCH (n:SYSTEM) return distinct n['id'] as id", type: "System Node" },
    { label: "System Family", key: "SYSTEM_FAMILY", type: "System Node", multi: true, range: 3 },
    { label: "System Type", key: "SYSTEM_TYPE", type: "System Node", multi: true, range: 3 },
    { label: "Program Status", key: "PROGRAM_STATUS", type: "System Node", multi: true, range: 2 },
    { label: "Prime/OEM", key: "PRIME-OEM", type: "System Node", multi: true, range: 2 },
    { label: "Customer", key: "Customer", type: "System Node", multi: true, range: 11 },
    { label: "Platform Specific", key: "id", query: "MATCH (n:PLATFORM) return distinct n['id'] as id", type: "Platform Node" },
    { label: "Platform Family", key: "PLATFORM_FAMILY", type: "Platform Node", multi: true, range: 2 },
    { label: "Platform Type", key: "TYPE", type: "Platform Node", multi: true, range: 2 },
    { label: "Platform Model/Version", key: "MODEL-VERSION", type: "Platform Node", multi: true, range: 2 },
    { label: "Prime/Integrator", key: "PRIME-INTEGRATOR", type: "Platform Node", multi: true, range: 2 },
    { label: "Customer", key: "Customer", type: "Platform Node", multi: true, range: 11 },
    { label: "Company", key: "id", query: "MATCH (n:COMPANY) return distinct n['id'] as id", type: "Company Node" },
    { label: "Corporate Group", key: "CORP_GROUP", type: "Company Node", multi: true, range: 2 },
    { label: "Market Segment", key: "MARKET_SEGMENT", type: "Company Node", multi: true, range: 5 },
    { label: "Country", key: "COUNTRY", type: "Company Node", query: "MATCH (n:COMPANY) return distinct n['COUNTRY'] as COUNTRY;" },
    { label: "Region", key: "REGION", type: "Company Node", query: "MATCH (n:COMPANY) return distinct n['REGION'] as REGION;" },
    { label: "Role", key: "ROLE", type: "Company System Edge", query: "MATCH (n)-[r]-(q) return DISTINCT r['ROLE'] as ROLE;" },
    { label: "Mission", key: "MISSION", type: "System Company Edge", multi: true, range: 3 },
    { label: "Operational Status", key: "OPERATIONAL_STATUS", type: "System Company Edge", query: "MATCH (n)-[r]-(q) return DISTINCT r['OPERATIONAL_STATUS'] as OPERATIONAL_STATUS;" }
];

export const MAE_MAIN_FILTERS = {
    nodes: [
        { name: "All", a1: "", a2: "" },
        { name: "System-Platform", a1: ":SYSTEM", a2: ":PLATFORM" },
        { name: "Company-System", a1: ":COMPANY", a2: ":SYSTEM" },
        { name: "System-System", a1: ":SYSTEM", a2: ":SYSTEM" }
    ],
    links: []
};

// currently used
export const LABS_FILTER_OPTIONS = [
    {
        heading: 'RELATIONSHIPS',
        filters: [
            [
                {
                    title: 'Tech Focus',
                    options: [
                        "example1",
                        "example2",
                        "example3"
                    ]
                },
                {
                    title: 'Alliance Type',
                    options: [
                        "example1",
                        "example2",
                        "example3"
                    ]
                }
            ]
        ]
    },
    {
        heading: 'ORGANIZATIONS',
        filters: [
            [
                {
                    title: 'Organization',
                    options: [
                        "example1",
                        "example2",
                        "example3"
                    ]
                },
                {
                    title: 'Group',
                    options: [
                        "example1",
                        "example2",
                        "example3"
                    ]
                },
                {
                    title: 'Affiliation',
                    options: [
                        "example1",
                        "example2",
                        "example3"
                    ]
                }
            ],
            [
                {
                    title: 'Competency',
                    options: [
                        "example1",
                        "example2",
                        "example3"
                    ]
                },
                {
                    title: 'Country',
                    options: [
                        "example1",
                        "example2",
                        "example3"
                    ]
                },
                {
                    title: 'Region',
                    options: [
                        "example1",
                        "example2",
                        "example3"
                    ]
                }
            ]
        ]
    }
];

// export const GROUPS = [
//     { label: "U.S. Army CCDC ARL", value: "U.S. Army CCDC ARL" },
//     { label: "USG", value: "USG" },
//     { label: "Lockheed Martin", value: "Lockheed Martin" },
//     { label: "U.S. Air Force Research Laboratory", value: "U.S. Air Force Research Laboratory" },
//     { label: "U.S. Air Force Test Center", value: "U.S. Air Force Test Center" },
//     { label: "University of Dayton", value: "University of Dayton" },
//     { label: "WBI", value: "WBI" },
//     { label: "Graf Research", value: "Graf Research" },
//     { label: "CMU", value: "CMU" },
//     { label: "MIT", value: "MIT" },
//     { label: "SpaceX", value: "SpaceX" },
//     { label: "NIST", value: "NIST" },
//     { label: "AIM Photonics", value: "AIM Photonics" },
//     { label: "SUNY", value: "SUNY" },
//     { label: "Griffiss Institute", value: "Griffiss Institute" },
//     { label: "IBM", value: "IBM" },
//     { label: "Battelle", value: "Battelle" },
//     { label: "Palantir", value: "Palantir" },
//     { label: "OSD", value: "OSD" },
//     { label: "University of Illinois", value: "University of Illinois" },
//     { label: "University of Arizona", value: "University of Arizona" },
//     { label: "Cummings Aerospace", value: "Cummings Aerospace" },
//     { label: "Aerospace", value: "Aerospace" },
//     { label: "DEFENSEWERX", value: "DEFENSEWERX" },
//     { label: "U.S. Army Corps of Engineers", value: "U.S. Army Corps of Engineers" },
//     { label: "USSOCOM", value: "USSOCOM" },
//     { label: "RAND", value: "RAND" },
//     { label: "NSA", value: "NSA" },
//     { label: "MITRE", value: "MITRE" },
//     { label: "IDA", value: "IDA" },
//     { label: "HQDA", value: "HQDA" },
//     { label: "HQ DON", value: "HQ DON" },
//     { label: "HAF", value: "HAF" },
//     { label: "CNA", value: "CNA" },
// ];

export const GROUPS = [
    {
        "label": "China Aerospace Science and Technology Corp.",
        "value": "China Aerospace Science and Technology Corp."
    }
    ,
    {
        "label": "U.S. Air Force Research Laboratory",
        "value": "U.S. Air Force Research Laboratory"
    }
    ,
    {
        "label": "U.S. DoD",
        "value": "U.S. DoD"
    }
    ,
    {
        "label": "Autodesk",
        "value": "Autodesk"
    }
    ,
    {
        "label": "Aviation Industry Corp. of China",
        "value": "Aviation Industry Corp. of China"
    }
    ,
    {
        "label": "Ministry of Industry & Info. Tech. (PRC)",
        "value": "Ministry of Industry & Info. Tech. (PRC)"
    }
    ,
    {
        "label": "Beijing Aerospace FCC",
        "value": "Beijing Aerospace FCC"
    }
    ,
    {
        "label": "Beijing IoT",
        "value": "Beijing IoT"
    }
    ,
    {
        "label": "Beihang Univ.",
        "value": "Beihang Univ."
    }
    ,
    {
        "label": "CMU",
        "value": "CMU"
    }
    ,
    {
        "label": "Georgia Tech",
        "value": "Georgia Tech"
    }
    ,
    {
        "label": "Emory Univ.",
        "value": "Emory Univ."
    }
    ,
    {
        "label": "CSIC/CSSC",
        "value": "CSIC/CSSC"
    }
    ,
    {
        "label": "Dell Technology Group",
        "value": "Dell Technology Group"
    }
    ,
    {
        "label": "Ecoles Centrales Group",
        "value": "Ecoles Centrales Group"
    }
    ,
    {
        "label": "ENI Metz",
        "value": "ENI Metz"
    }
    ,
    {
        "label": "Gomel State Univ.",
        "value": "Gomel State Univ."
    }
    ,
    {
        "label": "Google",
        "value": "Google"
    }
    ,
    {
        "label": "U.S. Army DEVCOM",
        "value": "U.S. Army DEVCOM"
    }
    ,
    {
        "label": "Harbin Engineering Univ.",
        "value": "Harbin Engineering Univ."
    }
    ,
    {
        "label": "Harbin IoT",
        "value": "Harbin IoT"
    }
    ,
    {
        "label": "Hunan Univ. of S&T",
        "value": "Hunan Univ. of S&T"
    }
    ,
    {
        "label": "Honeywell Tridium",
        "value": "Honeywell Tridium"
    }
    ,
    {
        "label": "Huawei Technologies",
        "value": "Huawei Technologies"
    }
    ,
    {
        "label": "IBM",
        "value": "IBM"
    }
    ,
    {
        "label": "Imperial College London",
        "value": "Imperial College London"
    }
    ,
    {
        "label": "Intel",
        "value": "Intel"
    }
    ,
    {
        "label": "Kogakuin UoT&E",
        "value": "Kogakuin UoT&E"
    }
    ,
    {
        "label": "Lagos Univ.",
        "value": "Lagos Univ."
    }
    ,
    {
        "label": "Merrill Lynch",
        "value": "Merrill Lynch"
    }
    ,
    {
        "label": "Microsoft",
        "value": "Microsoft"
    }
    ,
    {
        "label": "MSU",
        "value": "MSU"
    }
    ,
    {
        "label": "National Instruments",
        "value": "National Instruments"
    }
    ,
    {
        "label": "Northwestern Polytechnical Univ.",
        "value": "Northwestern Polytechnical Univ."
    }
    ,
    {
        "label": "Nanjing Univ. of S&T",
        "value": "Nanjing Univ. of S&T"
    }
    ,
    {
        "label": "NORINCO",
        "value": "NORINCO"
    }
    ,
    {
        "label": "Ministry of Education",
        "value": "Ministry of Education"
    }
    ,
    {
        "label": "Peking Univ.",
        "value": "Peking Univ."
    }
    ,
    {
        "label": "Penn State",
        "value": "Penn State"
    }
    ,
    {
        "label": "Rockwell",
        "value": "Rockwell"
    }
    ,
    {
        "label": "Slovak Academy of Science",
        "value": "Slovak Academy of Science"
    }
    ,
    {
        "label": "Stanford Univ.",
        "value": "Stanford Univ."
    }
    ,
    {
        "label": "SUNY",
        "value": "SUNY"
    }
    ,
    {
        "label": "Synopsys",
        "value": "Synopsys"
    }
    ,
    {
        "label": "Tektronix Technology",
        "value": "Tektronix Technology"
    }
    ,
    {
        "label": "Texas Instruments",
        "value": "Texas Instruments"
    }
    ,
    {
        "label": "Delft UoT",
        "value": "Delft UoT"
    }
    ,
    {
        "label": "Kosice Technical Univ.",
        "value": "Kosice Technical Univ."
    }
    ,
    {
        "label": "Univ. of Manchester",
        "value": "Univ. of Manchester"
    }
    ,
    {
        "label": "Univ. of Nottingham",
        "value": "Univ. of Nottingham"
    }
    ,
    {
        "label": "Univ. of Saskatchewan",
        "value": "Univ. of Saskatchewan"
    }
    ,
    {
        "label": "Univ. of Strathclyde",
        "value": "Univ. of Strathclyde"
    }
    ,
    {
        "label": "Univ. of Surrey",
        "value": "Univ. of Surrey"
    }
    ,
    {
        "label": "Univ. of Washington",
        "value": "Univ. of Washington"
    }
    ,
    {
        "label": "Univ. of Zilina",
        "value": "Univ. of Zilina"
    }
    ,
    {
        "label": "Univ. of Arizona",
        "value": "Univ. of Arizona"
    }
    ,
    {
        "label": "Univ. of Illinois",
        "value": "Univ. of Illinois"
    }
    ,
    {
        "label": "Univ. of Wollongong",
        "value": "Univ. of Wollongong"
    }
    ,
    {
        "label": "Waseda Univ.",
        "value": "Waseda Univ."
    }
    ,
    {
        "label": "Zvolen UoT",
        "value": "Zvolen UoT"
    }
];