import * as React from "react";
import Iframe from "react-iframe";
import { Link } from 'react-router-dom';

export class NewsAnalysisComponent extends React.Component {
  cursorStyle = { cursor: 'pointer' };

  constructor(props) {
    super(props);
    this.clickArticle = this.clickArticle.bind(this);
    this.goBack = this.goBack.bind(this);
    this.getTOC = this.getTOC.bind(this);
    this.getArticles = this.getArticles.bind(this);
    this.fetchArticles = this.fetchArticles.bind(this);
    this.state = {
      articles: [],
      selectedArticle: {},
      showTOC: true
    }
  }

  componentDidMount() {
    // this.fetchArticles();
  }

  goBack() {
    this.setState({ showTOC: true });
  }

  clickArticle(article) {
    this.setState({ selectedArticle: article, showTOC: false });
  }

  getTOC() {
    return <div>
      <div className="row" >
        <div className="col-12">
          <a onClick={this.goBack} style={this.cursorStyle}>
            &larr; Go back to Table Of Contents
            </a>
        </div>
      </div >
      <div className="row">
        <div className="col-12">
          <div dangerouslySetInnerHTML={{ __html: this.state['selectedArticle'].article }} />
        </div>
      </div>
    </div>
  };

  fetchArticles() {
    fetch('http://localhost:3714/api/articles')
      .then(function (response) {
        return response.json();
      })
      .then(d => {
        d = d.map((art) => {
          art['date'] = (new Date(art['date'])).toDateString();
          return art;
        })
          .sort((a, b) => a.date - b.date)
        this.setState({ articles: d });
      })
  }

  // tableau embeds not working. find a fix
  getArticles() {
    const articles = this.state['articles'];
    const readMore = 'Read more »';

    return articles.map((article, index) => {

      return <div key={index} className="row">
        <div className="col-12">
          <h2 onClick={this.clickArticle.bind(this, article)}
            style={this.cursorStyle}
            className="za-about-subject"
            dangerouslySetInnerHTML={{ __html: article['heading'] }} />
          <h4>{article['date']}</h4>
          <p dangerouslySetInnerHTML={{ __html: article['sample'] }} />
          <p onClick={this.clickArticle.bind(this, article)} style={this.cursorStyle}>
            {readMore}
          </p>
        </div>
      </div>
    });
  };

  render() {
    const footer = <div className="row">
      <div className="col-sm-12">
        <p style={{ marginTop: '20px' }}>
          <b><em>
            For more insights, join our Fast Tracks <Link to='/contact'>mailing list</Link> and/or request a <Link to='contact'>demo</Link> of the Sightline market visualization tool.
          </em></b>
        </p>
      </div>
    </div>;

    const articleView = this.state['showTOC'] ? this.getArticles() : this.getTOC();

    return (
      <section className="news-analysis-component">
        <div className="za-body">
          <div className="za-header">
            <h1 className="za-title za-title-medium">Fast Tracks</h1>
          </div>
          <div className="actionate">
            {articleView}

            <Iframe url="http://13.126.130.210/zeta/"
              width="100%"
              height="1200px"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"
              allowFullScreen={true} />

            {footer}
          </div>

        </div>
      </section>
    );
  }
}