import React, { useEffect } from 'react';
import { HashRouter, Route } from 'react-router-dom';

import NavbarComponent from './components/navbar/navbar';
import RouteGuard from "./RouteGuard"
// import SidebarComponent from './components/sidebar/Sidebar';
import './App.css';
import { fetchFilters } from "./redux/reducers/chartData/actions";

import router from "./Router";
import Login from './views/login'

const App = () => {
  const basename = '/'//'/zetanalytics/'

  // on component load
  useEffect(() => {
    // dispatch(fetchFilters('mae'));
    // dispatch(fetchFilters('labs'));
  }, []);

  const routeViews = router.map(item => {
    if (item['name'] !== 'graphs') {
      return <RouteGuard
        key={item['name']}
        path={item['path']}
        exact={true}
        component={item['main']} />;
    } else {
      return item['routes'].map((child) => {
        return <RouteGuard
          key={child["name"]}
          path={child["path"]}
          exact={true}
          component={child["main"]} />;
      });
    }
  }).flat();

  return (
    <HashRouter basename={basename}>
      <NavbarComponent />
      {/* <SidebarComponent /> */}
      <div className="App">
        <div className="container-fluid">
          {routeViews}
          <Route
            key='login'
            path='/login'
            exact={true}
            component={() => <Login />} />
        </div>
      </div>
    </HashRouter>
  );
};

export default App;
